import React from 'react';
import { Link } from 'react-router-dom';
import './LeftSideMenu.css'; // Bu CSS dosyasını oluşturmayı unutmayın

const LeftSideMenu = ({ isActive, onToggle }) => {
  if (!isActive) return null;

  return (
    <div className="left-side">
      <div className="bg" />
     
      
      <div className="frame-parent">
        <div className="frame-group">
          <div className="myfinans-logo-3-1-parent">
            <img
              className="myfinans-logo-3-1-icon"
              alt=""
              src="/myfinanslogo3-1@2x.png"
            />
            <div className="text-wrapper">
              <div className="text1">
                <span>my</span>
                <span className="finans">Finans</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="dashboard-parent">
          <Link to="/" className="dashboard">
            <div className="button-bg" />
            <b className="text2">Ana Sayfa</b>
            <img
              className="dashboard-icon"
              alt=""
              src="/dashboardicon.svg"
            />
          </Link>
          <Link to="/panel" className="exchange">
            <div className="text3">Piyasalar</div>
            <img className="exchange-icon" alt="" src="/exchangeicon.svg" />
          </Link>
          <Link to="/test" className="exchange">

          <div className="live-prices">
            <div className="text4">Sayfam</div>
            <img className="price-icon" alt="" src="/priceicon.svg" />
          </div>
           </Link>
         
        </div>
      </div>
    </div>
  );
};

export default LeftSideMenu;