import React from 'react';
import './AnaSayfa.css'; // Stil dosyasını oluşturmayı unutmayın

const TopMenu = ({ onMenuToggle, onSearchClick }) => {
  return (
    <div className="mega-menue-parent">
      <img onClick={onMenuToggle} className="mega-menue-icon" alt="" src="/megamenue.svg" />
      
      <div className="search-box-parent">
        <div className="search-box" onClick={onSearchClick}>
          <div className="bg1" />
          <div className="search">
            <div className="text10">Arama Yap</div>
            <img
              className="vuesaxtwotonesearch-normal-icon"
              alt=""
              src="/vuesaxtwotonesearchnormal.svg"
            />
          </div>
        </div>
        <div className="image-1-parent">
          <img className="image-1-icon" alt="" src="/image-1@2x.png" />
          <div className="text11">Canlı Veri</div>
        </div>
        <div className="wallet-parent">
          <img className="notification" alt="" src="/wallet.svg" />
          <img className="notification" alt="" src="/settings.svg" />
          <div className="notification">
            <div className="bg2" />
            <div className="notification1">
              <img
                className="notification-icon"
                alt=""
                src="/notificationicon.svg"
              />
              <div className="number">
                <div className="rectangle-parent">
                  <div className="instance-child" />
                  <div className="text12">8</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;