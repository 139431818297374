import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./GirisYapMobil.module.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const GirisYapMobil = () => {
  const [email, setEmail] = useState("");
  const [sifre, setSifre] = useState("");
  const [uyari, setUyari] = useState("");
  const navigate = useNavigate();

  const kayitOlHandler = () => {
    navigate('/kayit');
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    setUyari("");
    
    try {
      const auth = getAuth();
      const response = await signInWithEmailAndPassword(auth, email, sifre);
      const user = response.user;
      
      // Kullanıcı verilerini localStorage'a kaydet
      localStorage.setItem('user', JSON.stringify({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified
      }));
      
      navigate('/panel');
    } catch (error) {
      console.error(error);
      setUyari("Email veya Şifre Hatası!");
    }
  };

  return (
    <div className={styles.girisYapMobil}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-12@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textParent}>
          <b className={styles.text1}>Yeni Nesil Analiz Platformu</b>
          <div className={styles.textGroup}>
            <b className={styles.text2}>Giriş Yap</b>
            <div className={styles.text3}>
              myFinans mobil uygulamasındaki giriş bilgileriniz ile giriş
              yapabilirsiniz.
            </div>
            <div className={styles.selectWrapper}>
              <div className={styles.select}>
                <div className={styles.selectChild} />
                  <div className={styles.text5} onClick={kayitOlHandler}>Kayıt</div>
                <div className={styles.textContainer}>

                <div className={styles.text4}>Giriş</div>
                </div>

              </div>
            </div>
            <div className={styles.emailAddress}>
              <div className={styles.component2}>
                <div className={styles.frameGroup}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.text6}>Full name</div>
                    <img
                      className={styles.frameItem}
                      alt=""
                      src="/line-187.svg"
                    />
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="/group-20468.svg"
                    />
                  </div>
                  <div className={styles.text7}>Erfan amade</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-20470.svg"
                  />
                  <img className={styles.lineIcon} alt="" src="/line-338.svg" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Addresi"
                  className={styles.text8}
                />
                <img
                  className={styles.component2Child}
                  alt=""
                  src="/line-188.svg"
                />
              </div>
              <img className={styles.checkIcon} alt="" src="/check.svg" />
              <img className={styles.mailIcon} alt="" src="/mailicon.svg" />
            </div>
            <div className={styles.emailAddress}>
              <div className={styles.component2}>
                <div className={styles.frameGroup}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.text6}>Full name</div>
                    <img
                      className={styles.frameItem}
                      alt=""
                      src="/line-187.svg"
                    />
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="/group-20468.svg"
                    />
                  </div>
                  <div className={styles.text7}>Erfan amade</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-20470.svg"
                  />
                  <img className={styles.lineIcon} alt="" src="/line-338.svg" />
                </div>
                <input
                  type="password"
                  value={sifre}
                  onChange={(e) => setSifre(e.target.value)}
                  placeholder="Şifre"
                  className={styles.text8}
                />
                <img
                  className={styles.component2Child}
                  alt=""
                  src="/line-188.svg"
                />
              </div>
              <img
                className={styles.passwordIcon}
                alt=""
                src="/passwordicon.svg"
              />
            </div>
            <button className={styles.button} onClick={loginHandler}>
              <div className={styles.buttonChild} />
              <div className={styles.rectangleContainer}>
                <div className={styles.groupChild} />
                <img className={styles.frameIcon} alt="" src="/frame.svg" />
              </div>
              <div className={styles.text12}>Giriş Yap</div>
            </button>
            {uyari && (
              <div className={styles.text13}>
                Uyarı !! {uyari}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GirisYapMobil;
