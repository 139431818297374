import styles from "./TakasWindow.module.css";
import React, { useEffect, useState,useRef,useCallback,useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot,doc,setDoc,increment,getDoc , serverTimestamp,updateDoc} from "firebase/firestore"; 

 
const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };
  
const TakasWindow = () => {
    



    const [showDatePicker, setShowDatePicker] = useState(false);

  
    const toggleMenu = () => {
      setMenuVisible(!isMenuVisible);
      };
      
    
      
      const aradegis = (ne) => {
        setaralik(ne)
        };
  
  
        const [urlsi, setUrlsi] = useState('investcodecompany.online');

        useEffect(() => {
    

            const fetchData = async () => {
              try {
           
            
                const q = query(
                  collection(db, "sunucu")
                );
            
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                  querySnapshot.forEach((docSnapshot) => {
                    
        
                      setUrlsi(docSnapshot.data().url)
                    
                  });
                });
            
                return () => {
                  unsubscribe();
                };
            
              } catch (error) {
               
              }
            };
            
            
              
            fetchData();
         
          }, []);
        
  
  
   
  
  
  
      const fetchStockdata = useCallback((hisse,tarih,urls) => {
        setLoaded(false)
        setsecili(hisse)
        setaramalistt([])
        setaranan(hisse)
  
        localStorage.setItem('sonArananHisse', hisse);

  
        const url = 'wss://'+urls+':443';
        console.log(url,hisse,tarih)
        wsRef.current = new WebSocket(url);
      
        wsRef.current.onopen = () => {
          wsRef.current.send(`takasanaliz,${hisse},${tarih}`);
        };
      
        wsRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            settoplamadet(data.t)
            data.i.sort((a, b) => b.a - a.a);  // Sort by 'a' in descending order
            setlistebu(data.i);
            setLoaded(true)
  
    
          } catch (e) {
            console.error('JSON parse error:', e);
            setLoaded(true)
  
          } finally {
            fetchStockPrice()
          }
        };
      
        wsRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
      
        wsRef.current.onclose = (event) => {
        };
      }, [])
  
  
  
  
  
      const veriyi_cek = async (hisse,ne) => {
       
      };
  
      const [hisseKodu, setHisseKodu] = useState(() => {
        // Önce localStorage'dan son aranan hisseyi al
        const sonArananHisse = localStorage.getItem('sonArananHisse');
        // Eğer varsa onu kullan, yoksa 'sonSeciliHisse'yi kontrol et
        // O da yoksa "ASELS" kullan
        return sonArananHisse || localStorage.getItem('sonSeciliHisse') || "ASELS";
      });
  
      const [secili, setsecili] = useState(hisseKodu);   
      
    
  
      const [aranan, setaranan] = useState(hisseKodu);

  
      
  
      const [hangi, sethangi] = useState(0);
  
      const [aralik, setaralik] = useState(0);
  
      const [aramalistt, setaramalistt] = useState([]);
          const [isimleri, setisimleri] = useState([
            "A1CAP",
            "ACSEL",
            "ADEL",
            "ADESE",
            "ADGYO",
            "AEFES",
            "AFYON",
            "AGESA",
            "AGHOL",
            "AGROT",
            "AGYO",
            "AHGAZ",
            "AKBNK",
            "AKCNS",
            "AKENR",
            "AKFGY",
            "AKFYE",
            "AKGRT",
            "AKMGY",
            "AKSA",
            "AKSEN",
            "AKSGY",
            "AKSUE",
            "AKYHO",
            "ALARK",
            "ALBRK",
            "ALCAR",
            "ALCTL",
            "ALFAS",
            "ALGYO",
            "ALKA",
            "ALKIM",
            "ALKLC",
            "ALMAD",
            "ALTINS1",
            "ALTNY",
            "ALVES",
            "ANELE",
            "ANGEN",
            "ANHYT",
            "ANSGR",
            "ARASE",
            "ARCLK",
            "ARDYZ",
            "ARENA",
            "ARSAN",
            "ARTMS",
            "ARZUM",
            "ASELS",
            "ASGYO",
            "ASTOR",
            "ASUZU",
            "ATAGY",
            "ATAKP",
            "ATATP",
            "ATEKS",
            "ATLAS",
            "ATSYH",
            "AVGYO",
            "AVHOL",
            "AVOD",
            "AVPGY",
            "AVTUR",
            "AYCES",
            "AYDEM",
            "AYEN",
            "AYES",
            "AYGAZ",
            "AZTEK",
            "BAGFS",
            "BAKAB",
            "BALAT",
            "BANVT",
            "BARMA",
            "BASCM",
            "BASGZ",
            "BAYRK",
            "BEGYO",
            "BERA",
            "BEYAZ",
            "BFREN",
            "BIENY",
            "BIGCH",
            "BIMAS",
            "BINHO",
            "BIOEN",
            "BIZIM",
            "BJKAS",
            "BLCYT",
            "BMSCH",
            "BMSTL",
            "BNTAS",
            "BOBET",
            "BORLS",
            "BORSK",
            "BOSSA",
            "BRISA",
            "BRKO",
            "BRKSN",
            "BRKVY",
            "BRLSM",
            "BRMEN",
            "BRSAN",
            "BRYAT",
            "BSOKE",
            "BTCIM",
            "BUCIM",
            "BURCE",
            "BURVA",
            "BVSAN",
            "BYDNR",
            "CANTE",
            "CASA",
            "CATES",
            "CCOLA",
            "CELHA",
            "CEMAS",
            "CEMTS",
            "CEOEM",
            "CIMSA",
            "CLEBI",
            "CMBTN",
            "CMENT",
            "CONSE",
            "COSMO",
            "CRDFA",
            "CRFSA",
            "CUSAN",
            "CVKMD",
            "CWENE",
            "DAGHL",
            "DAGI",
            "DAPGM",
            "DARDL",
            "DENGE",
            "DERHL",
            "DERIM",
            "DESA",
            "DESPC",
            "DEVA",
            "DGATE",
            "DGGYO",
            "DGNMO",
            "DIRIT",
            "DITAS",
            "DMRGD",
            "DMSAS",
            "DNISI",
            "DOAS",
            "DOBUR",
            "DOCO",
            "DOFER",
            "DOGUB",
            "DOHOL",
            "DOKTA",
            "DURDO",
            "DYOBY",
            "DZGYO",
            "EBEBK",
            "ECILC",
            "ECZYT",
            "EDATA",
            "EDIP",
            "EGEEN",
            "EGEPO",
            "EGGUB",
            "EGPRO",
            "EGSER",
            "EKGYO",
            "EKIZ",
            "EKOS",
            "EKSUN",
            "ELITE",
            "EMKEL",
            "EMNIS",
            "ENERY",
            "ENJSA",
            "ENKAI",
            "ENSRI",
            "ENTRA",
            "EPLAS",
            "ERBOS",
            "ERCB",
            "EREGL",
            "ERSU",
            "ESCAR",
            "ESCOM",
            "ESEN",
            "ETILR",
            "ETYAT",
            "EUHOL",
            "EUKYO",
            "EUPWR",
            "EUREN",
            "EUYO",
            "EYGYO",
            "FADE",
            "FENER",
            "FLAP",
            "FMIZP",
            "FONET",
            "FORMT",
            "FORTE",
            "FRIGO",
            "FROTO",
            "FZLGY",
            "GARAN",
            "GARFA",
            "GEDIK",
            "GEDZA",
            "GENIL",
            "GENTS",
            "GEREL",
            "GESAN",
            "GIPTA",
            "GLBMD",
            "GLCVY",
            "GLRYH",
            "GLYHO",
            "GMTAS",
            "GOKNR",
            "GOLTS",
            "GOODY",
            "GOZDE",
            "GRNYO",
            "GRSEL",
            "GRTRK",
            "GSDDE",
            "GSDHO",
            "GSRAY",
            "GUBRF",
            "GWIND",
            "GZNMI",
            "HALKB",
            "HATEK",
            "HATSN",
            "HDFGS",
            "HEDEF",
            "HEKTS",
            "HKTM",
            "HLGYO",
            "HOROZ",
            "HRKET",
            "HTTBT",
            "HUBVC",
            "HUNER",
            "HURGZ",
            "ICBCT",
            "ICUGS",
            "IDGYO",
            "IEYHO",
            "IHAAS",
            "IHEVA",
            "IHGZT",
            "IHLAS",
            "IHLGM",
            "IHYAY",
            "IMASM",
            "INDES",
            "INFO",
            "INGRM",
            "INTEM",
            "INVEO",
            "INVES",
            "IPEKE",
            "ISATR",
            "ISBIR",
            "ISBTR",
            "ISCTR",
            "ISDMR",
            "ISFIN",
            "ISGSY",
            "ISGYO",
            "ISKPL",
            "ISKUR",
            "ISMEN",
            "ISSEN",
            "ISYAT",
            "IZENR",
            "IZFAS",
            "IZINV",
            "IZMDC",
            "JANTS",
            "KAPLM",
            "KAREL",
            "KARSN",
            "KARTN",
            "KARYE",
            "KATMR",
            "KAYSE",
            "KBORU",
            "KCAER",
            "KCHOL",
            "KENT",
            "KERVN",
            "KERVT",
            "KFEIN",
            "KGYO",
            "KIMMR",
            "KLGYO",
            "KLKIM",
            "KLMSN",
            "KLNMA",
            "KLRHO",
            "KLSER",
            "KLSYN",
            "KMPUR",
            "KNFRT",
            "KOCMT",
            "KONKA",
            "KONTR",
            "KONYA",
            "KOPOL",
            "KORDS",
            "KOTON",
            "KOZAA",
            "KOZAL",
            "KRDMA",
            "KRDMB",
            "KRDMD",
            "KRGYO",
            "KRONT",
            "KRPLS",
            "KRSTL",
            "KRTEK",
            "KRVGD",
            "KSTUR",
            "KTLEV",
            "KTSKR",
            "KUTPO",
            "KUVVA",
            "KUYAS",
            "KZBGY",
            "KZGYO",
            "LIDER",
            "LIDFA",
            "LILAK",
            "LINK",
            "LKMNH",
            "LMKDC",
            "LOGO",
            "LRSHO",
            "LUKSK",
            "MAALT",
            "MACKO",
            "MAGEN",
            "MAKIM",
            "MAKTK",
            "MANAS",
            "MARBL",
            "MARKA",
            "MARTI",
            "MAVI",
            "MEDTR",
            "MEGAP",
            "MEGMT",
            "MEKAG",
            "MEPET",
            "MERCN",
            "MERIT",
            "MERKO",
            "METRO",
            "METUR",
            "MGROS",
            "MHRGY",
            "MIATK",
            "MIPAZ",
            "MMCAS",
            "MNDRS",
            "MNDTR",
            "MOBTL",
            "MOGAN",
            "MPARK",
            "MRGYO",
            "MRSHL",
            "MSGYO",
            "MTRKS",
            "MTRYO",
            "MZHLD",
            "NATEN",
            "NETAS",
            "NIBAS",
            "NTGAZ",
            "NTHOL",
            "NUGYO",
            "NUHCM",
            "OBAMS",
            "OBASE",
            "ODAS",
            "ODINE",
            "OFSYM",
            "ONCSM",
            "ONRYT",
            "ORCAY",
            "ORGE",
            "ORMA",
            "OSMEN",
            "OSTIM",
            "OTKAR",
            "OTTO",
            "OYAKC",
            "OYAYO",
            "OYLUM",
            "OYYAT",
            "OZGYO",
            "OZKGY",
            "OZRDN",
            "OZSUB",
            "OZYSR",
            "PAGYO",
            "PAMEL",
            "PAPIL",
            "PARSN",
            "PASEU",
            "PATEK",
            "PCILT",
            "PEHOL",
            "PEKGY",
            "PENGD",
            "PENTA",
            "PETKM",
            "PETUN",
            "PGSUS",
            "PINSU",
            "PKART",
            "PKENT",
            "PLTUR",
            "PNLSN",
            "PNSUT",
            "POLHO",
            "POLTK",
            "PRDGS",
            "PRKAB",
            "PRKME",
            "PRZMA",
            "PSDTC",
            "PSGYO",
            "QNBFB",
            "QNBFL",
            "QUAGR",
            "RALYH",
            "RAYSG",
            "REEDR",
            "RGYAS",
            "RNPOL",
            "RODRG",
            "ROYAL",
            "RTALB",
            "RUBNS",
            "RYGYO",
            "RYSAS",
            "SAFKR",
            "SAHOL",
            "SAMAT",
            "SANEL",
            "SANFM",
            "SANKO",
            "SARKY",
            "SASA",
            "SAYAS",
            "SDTTR",
            "SEGYO",
            "SEKFK",
            "SEKUR",
            "SELEC",
            "SELGD",
            "SELVA",
            "SEYKM",
            "SILVR",
            "SISE",
            "SKBNK",
            "SKTAS",
            "SKYLP",
            "SKYMD",
            "SMART",
            "SMRTG",
            "SNGYO",
            "SNICA",
            "SNKRN",
            "SNPAM",
            "SODSN",
            "SOKE",
            "SOKM",
            "SONME",
            "SRVGY",
            "SUMAS",
            "SUNTK",
            "SURGY",
            "SUWEN",
            "TABGD",
            "TARKM",
            "TATEN",
            "TATGD",
            "TAVHL",
            "TBORG",
            "TCELL",
            "TDGYO",
            "TEKTU",
            "TERA",
            "TETMT",
            "TEZOL",
            "TGSAS",
            "TKFEN",
            "TKNSA",
            "TLMAN",
            "TMPOL",
            "TMSN",
            "TNZTP",
            "TOASO",
            "TRCAS",
            "TRGYO",
            "TRILC",
            "TSGYO",
            "TSKB",
            "TSPOR",
            "TTKOM",
            "TTRAK",
            "TUCLK",
            "TUKAS",
            "TUPRS",
            "TUREX",
            "TURGG",
            "TURSG",
            "UFUK",
            "ULAS",
            "ULKER",
            "ULUFA",
            "ULUSE",
            "ULUUN",
            "UMPAS",
            "UNLU",
            "USAK",
            "UZERB",
            "VAKBN",
            "VAKFN",
            "VAKKO",
            "VANGD",
            "VBTYZ",
            "VERTU",
            "VERUS",
            "VESBE",
            "VESTL",
            "VKFYO",
            "VKGYO",
            "VKING",
            "VRGYO",
            "YAPRK",
            "YATAS",
            "YAYLA",
            "YBTAS",
            "YEOTK",
            "YESIL",
            "YGGYO",
            "YGYO",
            "YIGIT",
            "YKBNK",
            "YKSLN",
            "YONGA",
            "YUNSA",
            "YYAPI",
            "YYLGD",
            "ZEDUR",
            "ZOREN",
            "ZRGYO"
        ]
        );
          const [sirketadstam, setSirketAdstam] = useState([
          "A1CAP",
          "ACSEL",
          "ADEL",
          "ADESE",
          "ADGYO",
          "AEFES",
          "AFYON",
          "AGESA",
          "AGHOL",
          "AGROT",
          "AGYO",
          "AHGAZ",
          "AKBNK",
          "AKCNS",
          "AKENR",
          "AKFGY",
          "AKFYE",
          "AKGRT",
          "AKMGY",
          "AKSA",
          "AKSEN",
          "AKSGY",
          "AKSUE",
          "AKYHO",
          "ALARK",
          "ALBRK",
          "ALCAR",
          "ALCTL",
          "ALFAS",
          "ALGYO",
          "ALKA",
          "ALKIM",
          "ALKLC",
          "ALMAD",
          "ALTINS1",
          "ALTNY",
          "ALVES",
          "ANELE",
          "ANGEN",
          "ANHYT",
          "ANSGR",
          "ARASE",
          "ARCLK",
          "ARDYZ",
          "ARENA",
          "ARSAN",
          "ARTMS",
          "ARZUM",
          "ASELS",
          "ASGYO",
          "ASTOR",
          "ASUZU",
          "ATAGY",
          "ATAKP",
          "ATATP",
          "ATEKS",
          "ATLAS",
          "ATSYH",
          "AVGYO",
          "AVHOL",
          "AVOD",
          "AVPGY",
          "AVTUR",
          "AYCES",
          "AYDEM",
          "AYEN",
          "AYES",
          "AYGAZ",
          "AZTEK",
          "BAGFS",
          "BAKAB",
          "BALAT",
          "BANVT",
          "BARMA",
          "BASCM",
          "BASGZ",
          "BAYRK",
          "BEGYO",
          "BERA",
          "BEYAZ",
          "BFREN",
          "BIENY",
          "BIGCH",
          "BIMAS",
          "BINHO",
          "BIOEN",
          "BIZIM",
          "BJKAS",
          "BLCYT",
          "BMSCH",
          "BMSTL",
          "BNTAS",
          "BOBET",
          "BORLS",
          "BORSK",
          "BOSSA",
          "BRISA",
          "BRKO",
          "BRKSN",
          "BRKVY",
          "BRLSM",
          "BRMEN",
          "BRSAN",
          "BRYAT",
          "BSOKE",
          "BTCIM",
          "BUCIM",
          "BURCE",
          "BURVA",
          "BVSAN",
          "BYDNR",
          "CANTE",
          "CASA",
          "CATES",
          "CCOLA",
          "CELHA",
          "CEMAS",
          "CEMTS",
          "CEOEM",
          "CIMSA",
          "CLEBI",
          "CMBTN",
          "CMENT",
          "CONSE",
          "COSMO",
          "CRDFA",
          "CRFSA",
          "CUSAN",
          "CVKMD",
          "CWENE",
          "DAGHL",
          "DAGI",
          "DAPGM",
          "DARDL",
          "DENGE",
          "DERHL",
          "DERIM",
          "DESA",
          "DESPC",
          "DEVA",
          "DGATE",
          "DGGYO",
          "DGNMO",
          "DIRIT",
          "DITAS",
          "DMRGD",
          "DMSAS",
          "DNISI",
          "DOAS",
          "DOBUR",
          "DOCO",
          "DOFER",
          "DOGUB",
          "DOHOL",
          "DOKTA",
          "DURDO",
          "DYOBY",
          "DZGYO",
          "EBEBK",
          "ECILC",
          "ECZYT",
          "EDATA",
          "EDIP",
          "EFORC",
          "EGEEN",
          "EGEPO",
          "EGGUB",
          "EGPRO",
          "EGSER",
          "EKGYO",
          "EKIZ",
          "EKOS",
          "EKSUN",
          "ELITE",
          "EMKEL",
          "EMNIS",
          "ENERY",
          "ENJSA",
          "ENKAI",
          "ENSRI",
          "ENTRA",
          "EPLAS",
          "ERBOS",
          "ERCB",
          "EREGL",
          "ERSU",
          "ESCAR",
          "ESCOM",
          "ESEN",
          "ETILR",
          "ETYAT",
          "EUHOL",
          "EUKYO",
          "EUPWR",
          "EUREN",
          "EUYO",
          "EYGYO",
          "FADE",
          "FENER",
          "FLAP",
          "FMIZP",
          "FONET",
          "FORMT",
          "FORTE",
          "FRIGO",
          "FROTO",
          "FZLGY",
          "GARAN",
          "GARFA",
          "GEDIK",
          "GEDZA",
          "GENIL",
          "GENTS",
          "GEREL",
          "GESAN",
          "GIPTA",
          "GLBMD",
          "GLCVY",
          "GLRYH",
          "GLYHO",
          "GMTAS",
          "GOKNR",
          "GOLTS",
          "GOODY",
          "GOZDE",
          "GRNYO",
          "GRSEL",
          "GRTRK",
          "GSDDE",
          "GSDHO",
          "GSRAY",
          "GUBRF",
          "GWIND",
          "GZNMI",
          "HALKB",
          "HATEK",
          "HATSN",
          "HDFGS",
          "HEDEF",
          "HEKTS",
          "HKTM",
          "HLGYO",
          "HOROZ",
          "HRKET",
          "HTTBT",
          "HUBVC",
          "HUNER",
          "HURGZ",
          "ICBCT",
          "ICUGS",
          "IDGYO",
          "IEYHO",
          "IHAAS",
          "IHEVA",
          "IHGZT",
          "IHLAS",
          "IHLGM",
          "IHYAY",
          "IMASM",
          "INDES",
          "INFO",
          "INGRM",
          "INTEM",
          "INVEO",
          "INVES",
          "IPEKE",
          "ISATR",
          "ISBIR",
          "ISBTR",
          "ISCTR",
          "ISDMR",
          "ISFIN",
          "ISGSY",
          "ISGYO",
          "ISKPL",
          "ISKUR",
          "ISMEN",
          "ISSEN",
          "ISYAT",
          "IZENR",
          "IZFAS",
          "IZINV",
          "IZMDC",
          "JANTS",
          "KAPLM",
          "KAREL",
          "KARSN",
          "KARTN",
          "KARYE",
          "KATMR",
          "KAYSE",
          "KBORU",
          "KCAER",
          "KCHOL",
          "KENT",
          "KERVN",
          "KERVT",
          "KFEIN",
          "KGYO",
          "KIMMR",
          "KLGYO",
          "KLKIM",
          "KLMSN",
          "KLNMA",
          "KLRHO",
          "KLSER",
          "KLSYN",
          "KMPUR",
          "KNFRT",
          "KOCMT",
          "KONKA",
          "KONTR",
          "KONTRR",
          "KONYA",
          "KOPOL",
          "KORDS",
          "KOTON",
          "KOZAA",
          "KOZAL",
          "KRDMA",
          "KRDMB",
          "KRDMD",
          "KRGYO",
          "KRONT",
          "KRPLS",
          "KRSTL",
          "KRTEK",
          "KRVGD",
          "KSTUR",
          "KTLEV",
          "KTSKR",
          "KUTPO",
          "KUVVA",
          "KUYAS",
          "KZBGY",
          "KZGYO",
          "LIDER",
          "LIDFA",
          "LILAK",
          "LINK",
          "LKMNH",
          "LMKDC",
          "LOGO",
          "LRSHO",
          "LUKSK",
          "MAALT",
          "MACKO",
          "MAGEN",
          "MAKIM",
          "MAKTK",
          "MANAS",
          "MARBL",
          "MARKA",
          "MARTI",
          "MAVI",
          "MEDTR",
          "MEGAP",
          "MEGMT",
          "MEKAG",
          "MEPET",
          "MERCN",
          "MERIT",
          "MERKO",
          "METRO",
          "METUR",
          "MGROS",
          "MHRGY",
          "MIATK",
          "MIPAZ",
          "MMCAS",
          "MNDRS",
          "MNDTR",
          "MOBTL",
          "MOGAN",
          "MPARK",
          "MRGYO",
          "MRSHL",
          "MSGYO",
          "MTRKS",
          "MTRYO",
          "MZHLD",
          "NATEN",
          "NETAS",
          "NIBAS",
          "NTGAZ",
          "NTHOL",
          "NUGYO",
          "NUHCM",
          "OBAMS",
          "OBASE",
          "ODAS",
          "ODINE",
          "OFSYM",
          "ONCSM",
          "ONRYT",
          "ORCAY",
          "ORGE",
          "ORMA",
          "OSMEN",
          "OSTIM",
          "OTKAR",
          "OTTO",
          "OYAKC",
          "OYAYO",
          "OYLUM",
          "OYYAT",
          "OZGYO",
          "OZKGY",
          "OZRDN",
          "OZSUB",
          "OZYSR",
          "PAGYO",
          "PAMEL",
          "PAPIL",
          "PARSN",
          "PASEU",
          "PATEK",
          "PCILT",
          "PEHOL",
          "PEKGY",
          "PENGD",
          "PENTA",
          "PETKM",
          "PETUN",
          "PGSUS",
          "PINSU",
          "PKART",
          "PKENT",
          "PLTUR",
          "PNLSN",
          "PNSUT",
          "POLHO",
          "POLTK",
          "PRDGS",
          "PRKAB",
          "PRKME",
          "PRZMA",
          "PSDTC",
          "PSGYO",
          "QNBFB",
          "QNBFL",
          "QUAGR",
          "RALYH",
          "RAYSG",
          "REEDR",
          "RGYAS",
          "RNPOL",
          "RODRG",
          "ROYAL",
          "RTALB",
          "RUBNS",
          "RYGYO",
          "RYSAS",
          "SAFKR",
          "SAHOL",
          "SAMAT",
          "SANEL",
          "SANFM",
          "SANKO",
          "SARKY",
          "SASA",
          "SAYAS",
          "SDTTR",
          "SEGMN",
          "SEGYO",
          "SEKFK",
          "SEKUR",
          "SELEC",
          "SELGD",
          "SELVA",
          "SEYKM",
          "SILVR",
          "SISE",
          "SKBNK",
          "SKTAS",
          "SKYLP",
          "SKYMD",
          "SMART",
          "SMRTG",
          "SNGYO",
          "SNICA",
          "SNKRN",
          "SNPAM",
          "SODSN",
          "SOKE",
          "SOKM",
          "SONME",
          "SRVGY",
          "SUMAS",
          "SUNTK",
          "SURGY",
          "SUWEN",
          "TABGD",
          "TARKM",
          "TATEN",
          "TATGD",
          "TAVHL",
          "TBORG",
          "TCELL",
          "TDGYO",
          "TEKTU",
          "TERA",
          "TETMT",
          "TEZOL",
          "TGSAS",
          "THYAO",
          "TKFEN",
          "TKNSA",
          "TLMAN",
          "TMPOL",
          "TMSN",
          "TNZTP",
          "TOASO",
          "TRCAS",
          "TRGYO",
          "TRILC",
          "TSGYO",
          "TSKB",
          "TSPOR",
          "TTKOM",
          "TTRAK",
          "TUCLK",
          "TUKAS",
          "TUPRS",
          "TUREX",
          "TURGG",
          "TURSG",
          "UFUK",
          "ULAS",
          "ULKER",
          "ULUFA",
          "ULUSE",
          "ULUUN",
          "UMPAS",
          "UNLU",
          "USAK",
          "UZERB",
          "VAKBN",
          "VAKFN",
          "VAKKO",
          "VANGD",
          "VBTYZ",
          "VERTU",
          "VERUS",
          "VESBE",
          "VESTL",
          "VKFYO",
          "VKGYO",
          "VKING",
          "VRGYO",
          "YAPRK",
          "YATAS",
          "YAYLA",
          "YBTAS",
          "YEOTK",
          "YESIL",
          "YGGYO",
          "YGYO",
          "YIGIT",
          "YKBNK",
          "YKSLN",
          "YONGA",
          "YUNSA",
          "YYAPI",
          "YYLGD",
          "ZEDUR",
          "ZOREN",
          "ZRGYO"
      ]
        );
      
          const his_ara = async (hisse) => {
      
              setaranan(hisse.toUpperCase())
          
              if (aranan.length >= 2) {
              const arananHisseler = sirketadstam.filter((item) =>
              item.toUpperCase().startsWith(aranan)
          
          
            );
            setaramalistt(arananHisseler)
            
          
          }
            
            };
      
  
            const [seciligun, setseciligun] = useState(1);
  
  
  
            const [seciline, setseciline] = useState("ALANLAR");
            const [satmi, setsatmi] = useState("ALANLAR");
  
            const [yedek, setyedek] = useState([
              "A1CAP",
              "ACSEL",
              "ADEL",
              "ADESE",
              "ADGYO",
              "AEFES",
              "AFYON",
              "AGESA",
              "AGHOL",
              "AGROT",
              "AGYO",
              "AHGAZ",
              "AKBNK",
              "AKCNS",
              "AKENR",
              "AKFGY",
              "AKFYE",
              "AKGRT",
              "AKMGY",
              "AKSA",
              "AKSEN",
              "AKSGY",
              "AKSUE",
              "AKYHO",
              "ALARK",
              "ALBRK",
              "ALCAR",
              "ALCTL",
              "ALFAS",
              "ALGYO",
              "ALKA",
              "ALKIM",
              "ALKLC",
              "ALMAD",
              "ALTINS1",
              "ALTNY",
              "ALVES",
              "ANELE",
              "ANGEN",
              "ANHYT",
              "ANSGR",
              "ARASE",
              "ARCLK",
              "ARDYZ",
              "ARENA",
              "ARSAN",
              "ARTMS",
              "ARZUM",
              "ASELS",
              "ASGYO",
              "ASTOR",
              "ASUZU",
              "ATAGY",
              "ATAKP",
              "ATATP",
              "ATEKS",
              "ATLAS",
              "ATSYH",
              "AVGYO",
              "AVHOL",
              "AVOD",
              "AVPGY",
              "AVTUR",
              "AYCES",
              "AYDEM",
              "AYEN",
              "AYES",
              "AYGAZ",
              "AZTEK",
              "BAGFS",
              "BAKAB",
              "BALAT",
              "BANVT",
              "BARMA",
              "BASCM",
              "BASGZ",
              "BAYRK",
              "BEGYO",
              "BERA",
              "BEYAZ",
              "BFREN",
              "BIENY",
              "BIGCH",
              "BIMAS",
              "BINHO",
              "BIOEN",
              "BIZIM",
              "BJKAS",
              "BLCYT",
              "BMSCH",
              "BMSTL",
              "BNTAS",
              "BOBET",
              "BORLS",
              "BORSK",
              "BOSSA",
              "BRISA",
              "BRKO",
              "BRKSN",
              "BRKVY",
              "BRLSM",
              "BRMEN",
              "BRSAN",
              "BRYAT",
              "BSOKE",
              "BTCIM",
              "BUCIM",
              "BURCE",
              "BURVA",
              "BVSAN",
              "BYDNR",
              "CANTE",
              "CASA",
              "CATES",
              "CCOLA",
              "CELHA",
              "CEMAS",
              "CEMTS",
              "CEOEM",
              "CIMSA",
              "CLEBI",
              "CMBTN",
              "CMENT",
              "CONSE",
              "COSMO",
              "CRDFA",
              "CRFSA",
              "CUSAN",
              "CVKMD",
              "CWENE",
              "DAGHL",
              "DAGI",
              "DAPGM",
              "DARDL",
              "DENGE",
              "DERHL",
              "DERIM",
              "DESA",
              "DESPC",
              "DEVA",
              "DGATE",
              "DGGYO",
              "DGNMO",
              "DIRIT",
              "DITAS",
              "DMRGD",
              "DMSAS",
              "DNISI",
              "DOAS",
              "DOBUR",
              "DOCO",
              "DOFER",
              "DOGUB",
              "DOHOL",
              "DOKTA",
              "DURDO",
              "DYOBY",
              "DZGYO",
              "EBEBK",
              "ECILC",
              "ECZYT",
              "EDATA",
              "EDIP",
              "EFORC",
              "EGEEN",
              "EGEPO",
              "EGGUB",
              "EGPRO",
              "EGSER",
              "EKGYO",
              "EKIZ",
              "EKOS",
              "EKSUN",
              "ELITE",
              "EMKEL",
              "EMNIS",
              "ENERY",
              "ENJSA",
              "ENKAI",
              "ENSRI",
              "ENTRA",
              "EPLAS",
              "ERBOS",
              "ERCB",
              "EREGL",
              "ERSU",
              "ESCAR",
              "ESCOM",
              "ESEN",
              "ETILR",
              "ETYAT",
              "EUHOL",
              "EUKYO",
              "EUPWR",
              "EUREN",
              "EUYO",
              "EYGYO",
              "FADE",
              "FENER",
              "FLAP",
              "FMIZP",
              "FONET",
              "FORMT",
              "FORTE",
              "FRIGO",
              "FROTO",
              "FZLGY",
              "GARAN",
              "GARFA",
              "GEDIK",
              "GEDZA",
              "GENIL",
              "GENTS",
              "GEREL",
              "GESAN",
              "GIPTA",
              "GLBMD",
              "GLCVY",
              "GLRYH",
              "GLYHO",
              "GMTAS",
              "GOKNR",
              "GOLTS",
              "GOODY",
              "GOZDE",
              "GRNYO",
              "GRSEL",
              "GRTRK",
              "GSDDE",
              "GSDHO",
              "GSRAY",
              "GUBRF",
              "GWIND",
              "GZNMI",
              "HALKB",
              "HATEK",
              "HATSN",
              "HDFGS",
              "HEDEF",
              "HEKTS",
              "HKTM",
              "HLGYO",
              "HOROZ",
              "HRKET",
              "HTTBT",
              "HUBVC",
              "HUNER",
              "HURGZ",
              "ICBCT",
              "ICUGS",
              "IDGYO",
              "IEYHO",
              "IHAAS",
              "IHEVA",
              "IHGZT",
              "IHLAS",
              "IHLGM",
              "IHYAY",
              "IMASM",
              "INDES",
              "INFO",
              "INGRM",
              "INTEM",
              "INVEO",
              "INVES",
              "IPEKE",
              "ISATR",
              "ISBIR",
              "ISBTR",
              "ISCTR",
              "ISDMR",
              "ISFIN",
              "ISGSY",
              "ISGYO",
              "ISKPL",
              "ISKUR",
              "ISMEN",
              "ISSEN",
              "ISYAT",
              "IZENR",
              "IZFAS",
              "IZINV",
              "IZMDC",
              "JANTS",
              "KAPLM",
              "KAREL",
              "KARSN",
              "KARTN",
              "KARYE",
              "KATMR",
              "KAYSE",
              "KBORU",
              "KCAER",
              "KCHOL",
              "KENT",
              "KERVN",
              "KERVT",
              "KFEIN",
              "KGYO",
              "KIMMR",
              "KLGYO",
              "KLKIM",
              "KLMSN",
              "KLNMA",
              "KLRHO",
              "KLSER",
              "KLSYN",
              "KMPUR",
              "KNFRT",
              "KOCMT",
              "KONKA",
              "KONTR",
              "KONYA",
              "KOPOL",
              "KORDS",
              "KOTON",
              "KOZAA",
              "KOZAL",
              "KRDMA",
              "KRDMB",
              "KRDMD",
              "KRGYO",
              "KRONT",
              "KRPLS",
              "KRSTL",
              "KRTEK",
              "KRVGD",
              "KSTUR",
              "KTLEV",
              "KTSKR",
              "KUTPO",
              "KUVVA",
              "KUYAS",
              "KZBGY",
              "KZGYO",
              "LIDER",
              "LIDFA",
              "LILAK",
              "LINK",
              "LKMNH",
              "LMKDC",
              "LOGO",
              "LRSHO",
              "LUKSK",
              "MAALT",
              "MACKO",
              "MAGEN",
              "MAKIM",
              "MAKTK",
              "MANAS",
              "MARBL",
              "MARKA",
              "MARTI",
              "MAVI",
              "MEDTR",
              "MEGAP",
              "MEGMT",
              "MEKAG",
              "MEPET",
              "MERCN",
              "MERIT",
              "MERKO",
              "METRO",
              "METUR",
              "MGROS",
              "MHRGY",
              "MIATK",
              "MIPAZ",
              "MMCAS",
              "MNDRS",
              "MNDTR",
              "MOBTL",
              "MOGAN",
              "MPARK",
              "MRGYO",
              "MRSHL",
              "MSGYO",
              "MTRKS",
              "MTRYO",
              "MZHLD",
              "NATEN",
              "NETAS",
              "NIBAS",
              "NTGAZ",
              "NTHOL",
              "NUGYO",
              "NUHCM",
              "OBAMS",
              "OBASE",
              "ODAS",
              "ODINE",
              "OFSYM",
              "ONCSM",
              "ONRYT",
              "ORCAY",
              "ORGE",
              "ORMA",
              "OSMEN",
              "OSTIM",
              "OTKAR",
              "OTTO",
              "OYAKC",
              "OYAYO",
              "OYLUM",
              "OYYAT",
              "OZGYO",
              "OZKGY",
              "OZRDN",
              "OZSUB",
              "OZYSR",
              "PAGYO",
              "PAMEL",
              "PAPIL",
              "PARSN",
              "PASEU",
              "PATEK",
              "PCILT",
              "PEHOL",
              "PEKGY",
              "PENGD",
              "PENTA",
              "PETKM",
              "PETUN",
              "PGSUS",
              "PINSU",
              "PKART",
              "PKENT",
              "PLTUR",
              "PNLSN",
              "PNSUT",
              "POLHO",
              "POLTK",
              "PRDGS",
              "PRKAB",
              "PRKME",
              "PRZMA",
              "PSDTC",
              "PSGYO",
              "QNBFB",
              "QNBFL",
              "QUAGR",
              "RALYH",
              "RAYSG",
              "REEDR",
              "RGYAS",
              "RNPOL",
              "RODRG",
              "ROYAL",
              "RTALB",
              "RUBNS",
              "RYGYO",
              "RYSAS",
              "SAFKR",
              "SAHOL",
              "SAMAT",
              "SANEL",
              "SANFM",
              "SANKO",
              "SARKY",
              "SASA",
              "SAYAS",
              "SDTTR",
              "SEGMN",
              "SEGYO",
              "SEKFK",
              "SEKUR",
              "SELEC",
              "SELGD",
              "SELVA",
              "SEYKM",
              "SILVR",
              "SISE",
              "SKBNK",
              "SKTAS",
              "SKYLP",
              "SKYMD",
              "SMART",
              "SMRTG",
              "SNGYO",
              "SNICA",
              "SNKRN",
              "SNPAM",
              "SODSN",
              "SOKE",
              "SOKM",
              "SONME",
              "SRVGY",
              "SUMAS",
              "SUNTK",
              "SURGY",
              "SUWEN",
              "TABGD",
              "TARKM",
              "TATEN",
              "TATGD",
              "TAVHL",
              "TBORG",
              "TCELL",
              "TDGYO",
              "TEKTU",
              "TERA",
              "TETMT",
              "TEZOL",
              "TGSAS",
              "THYAO",
              "TKFEN",
              "TKNSA",
              "TLMAN",
              "TMPOL",
              "TMSN",
              "TNZTP",
              "TOASO",
              "TRCAS",
              "TRGYO",
              "TRILC",
              "TSGYO",
              "TSKB",
              "TSPOR",
              "TTKOM",
              "TTRAK",
              "TUCLK",
              "TUKAS",
              "TUPRS",
              "TUREX",
              "TURGG",
              "TURSG",
              "UFUK",
              "ULAS",
              "ULKER",
              "ULUFA",
              "ULUSE",
              "ULUUN",
              "UMPAS",
              "UNLU",
              "USAK",
              "UZERB",
              "VAKBN",
              "VAKFN",
              "VAKKO",
              "VANGD",
              "VBTYZ",
              "VERTU",
              "VERUS",
              "VESBE",
              "VESTL",
              "VKFYO",
              "VKGYO",
              "VKING",
              "VRGYO",
              "YAPRK",
              "YATAS",
              "YAYLA",
              "YBTAS",
              "YEOTK",
              "YESIL",
              "YGGYO",
              "YGYO",
              "YIGIT",
              "YKBNK",
              "YKSLN",
              "YONGA",
              "YUNSA",
              "YYAPI",
              "YYLGD",
              "ZEDUR",
              "ZOREN",
              "ZRGYO"
          ]
            );
          
  
            const [date, setDate] = useState(new Date());
    const [show, setShow] = useState(false);
  
    const [toplamadet, settoplamadet] = useState(0);
  
  
    const formatDate2 = (date) => {
      return date.toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };
    const formatDate3 = (date) => {
      return date.toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };
  
    const getPreviousWeekday = (date) => {
      const dayOfWeek = date.getDay();
      if (dayOfWeek === 0) { // Pazar
        date.setDate(date.getDate() - 2);
      } else if (dayOfWeek === 6) { // Cumartesi
        date.setDate(date.getDate() - 1);
      } else if (dayOfWeek === 1) { // Pazartesi
        date.setDate(date.getDate() - 3);
      }
      
  
      return date;
    };
  
    useEffect(() => {
        setsecili(hisseKodu);
        const fetchPreviousWeekday = async () => {
          const previousWeekday = await getPreviousWeekday(new Date());
          setDate(previousWeekday);
          setseciligun(formatDate(previousWeekday));
          
          try {
            return fetchStockdata(hisseKodu, formatDate(previousWeekday), urlsi);
          } catch (error) {
            console.log(error);
          }  
        };
    
        fetchPreviousWeekday();
      }, [hisseKodu, fetchStockdata, urlsi]);
    
  
        useEffect(()=> {
    
        
    
  
          setaranan(aranan)
          setaramalistt([])
    
       
  
    
      },[])
  
      const anaSayfa = async () => {
        navigation.navigate('anaSayfa', {
        });
    };
    
    const onChange = (event, selectedDate) => {
      setaralik(0)
      const currentDate = selectedDate || date;
      setShow(Platform.OS === 'ios');
      setDate(currentDate);
      fetchStockdata(tarih=currentDate);
  
  
    };
   
  
    
    const [showStart, setShowStart] = useState(false);
  
    const onChangeStart = (event, selectedDate) => {
      setShowDatePicker(false);
      const currentDate = selectedDate || startDate;
      setShowStart(Platform.OS === 'ios');
      setDate(currentDate)
      fetchStockdata(secili, formatDate(currentDate),urlsi);
    };
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    };
    const showDatepicker = () => {
      setShow(true);
    };
  
  
    const fetchStockNames = async () => {
      try {
        const response = await axios.get('https://finans.mynet.com/borsa/hisseler/', {
          headers: { 'User-Agent': 'Mozilla/5.0' } // Prevent potential blocking
        });
        const html = response.data;
    
        const root = parse(html);
        const rows = root.querySelectorAll('table tbody tr');
    
        const names = rows.map(row => {
          const nameElement = row.querySelector('td:first-child strong a');
          return nameElement ? nameElement.text.trim().split(' ')[0] : null;
        }).filter(Boolean);
    
        setSirketAdstam(names)
        setisimleri(names)
      } catch (error) {
        setSirketAdstam(yedek)
        setisimleri(yedek)
        console.error('Error fetching stock data:', error);
      }
    };
  
    const getFullName = (code) => {
      const institution = financialInstitutions.find(item => item[1] === code);
      return institution ? institution[0] : code;
    };
  
    const financialInstitutions = [
      ["A1 Capital", "ACP"],
      ["Acar", "ACA"],
      ["Adabank", "ADA"],
      ["Ag Kiy. Mad.", "WCV"],
      ["Agabullion Kiy. Mad.", "VAC"],
      ["Ahlatci", "AYX"],
      ["Ahlatci Doviz Kiy. Mad. Yet. Mues.", "WBI"],
      ["Ahlatci Metal Rafineri", "WMS"],
      ["Ak Yatirim", "AKM"],
      ["Akbank", "AKB"],
      ["Akbank (Portfoy Saklama)", "APS"],
      ["Akdeniz Menkul", "AKD"],
      ["Aktif Yatirim Bankasi", "AFB"],
      ["Alan", "ALN"],
      ["ALB", "ALM"],
      ["Albaraka Turk Katilim", "ALK"],
      ["Albayrak Doviz Altin Yet. Mues.", "WEQ"],
      ["Alfa Menkul", "ALF"],
      ["Allbatross", "BAH"],
      ["Alnus", "ANC"],
      ["Alternatif", "AMK"],
      ["Alternatif Bank", "ANF"],
      ["Altinbas Kiy. Mad.", "WAI"],
      ["Altinkaynak Doviz Kiy. Mad. Yet. Mues.", "WAJ"],
      ["Anadolu", "ADY"],
      ["Anadolubank", "ADB"],
      ["Arap Turk Bankasi", "ARA"],
      ["Ata", "ATA"],
      ["Atanur Kuyumculuk", "WCS"],
      ["BAB Menkul", "BAB"],
      ["Bank Of America", "MLB"],
      ["Bankpozitif", "POZ"],
      ["Birlesik Fon Bankasi", "BYR"],
      ["Bist Buyin Market Operation", "XCH"],
      ["Bist Market Operation", "BIST"],
      ["Bist Market Operation - Pmd", "BISTP"],
      ["Bist Offical Auction Market Operation", "XRM"],
      ["Bizim", "BMK"],
      ["Blupay Menkul", "BLU"],
      ["Bonus Kiy. Mad.", "WFW"],
      ["Bulls Yatirim", "BLS"],
      ["Burgan", "EFG"],
      ["Burgan Bank", "BUR"],
      ["Burgan Bank (Yabanci)", "EBY"],
      ["Censa Menkul", "CSA"],
      ["Ceros Menkul", "CRM"],
      ["Citi", "CIM"],
      ["Citibank", "CIT"],
      ["Citibank Yabanci", "CIY"],
      ["Citak Yet. Mues.", "WBF"],
      ["Colendi", "COL"],
      ["Credit Suisse", "CSM"],
      ["Deha Menkul", "DHA"],
      ["Demas Kuyumculuk", "VAI"],
      ["Denge Doviz Kiy. Mad. Yet. Mues.", "WAE"],
      ["Deniz", "DZY"],
      ["Denizbank", "DNZ"],
      ["Denizbank (Portfoy Saklama)", "DNS"],
      ["Destek", "DTB"],
      ["Deutsche Bank", "DBI"],
      ["Deutsche Bank (Yabanci)", "DBY"],
      ["Diler Yatirim Bankasi", "DLR"],
      ["Dinamik", "DMK"],
      ["Doruk Doviz Kiy. Mad. Yet. Mues.", "WAR"],
      ["Ehg Yet. Mues.", "WGD"],
      ["Ekspres Menkul", "EKS"],
      ["Eliptik", "ELP"],
      ["Emeklilik Fonlari", "XEM"],
      ["Endeks Turev", "END"],
      ["Erciyes Menul", "ERY"],
      ["Eti Menkul", "ETM"],
      ["Euroclear Participant", "ECL"],
      ["Eximbank", "THR"],
      ["Fark-Toplam", "ZZX"],
      ["Fetih Doviz Kiy. Mad. Yet. Mues.", "WCU"],
      ["Fibabanka", "FBB"],
      ["Finans (Poftfoy Saklama)", "FNS"],
      ["Finansbank", "FIN"],
      ["Fours Kiy. Mad.", "VAO"],
      ["Galata", "GLT"],
      ["Garanti", "GRM"],
      ["Garanti Bankasi", "TGB"],
      ["Garanti Bankasi (Portfoy Saklama)", "GPS"],
      ["GCM", "GCM"],
      ["Gedik", "GDK"],
      ["GFC General", "GFC"],
      ["Global", "GLB"],
      ["Golden Global Yatirim Bankasi", "GGI"],
      ["Goldex Kuyumculuk", "VAM"],
      ["Goldtrans", "VAJ"],
      ["Gram Kiy. Mad.", "WIX"],
      ["Gsd Yatirim Bankasi", "GSB"],
      ["Guven Menkul", "GVN"],
      ["Guven Yet. Mues.", "WAM"],
      ["Hakan Yet. Mues.", "WGC"],
      ["Halk", "HLY"],
      ["Halk Bankasi", "THL"],
      ["Halk Bankasi (Portfoy Saklama)", "HLS"],
      ["Has Gumus Deg. Mad.", "WWL"],
      ["Has Kiy. Mad.", "WBZ"],
      ["Hazine Mustesarligi", "HZN"],
      ["Haznedar Yet. Mues.", "WLV"],
      ["HSBC", "HSY"],
      ["HSBC Bank", "HSB"],
      ["HSBC Bank (Yabanci)", "HYA"],
      ["IAR", "WAG"],
      ["IAR Doviz Kiy. Mad. Yet. Mues.", "WCF"],
      ["ICBC", "ICT"],
      ["ICBC Bank", "ICB"],
      ["Ikon Menkul", "IKN"],
      ["Info", "IYF"],
      ["Ing", "GNI"],
      ["Ing Bank", "GIN"],
      ["Ing Bank (Portfoy Saklama)", "GIP"],
      ["Integral", "INM"],
      ["Intl Hrm Kiy. Mad.", "VAH"],
      ["InvestAZ", "IAZ"],
      ["Is Bankasi (Portfoy Saklama)", "IPS"],
      ["Is Yatirim", "IYM"],
      ["Isbankasi", "TIB"],
      ["Isgold Altin Rafinerisi", "VAA"],
      ["Jp Morgan Chasebank", "TCM"],
      ["Kalkinma", "KMD"],
      ["Kare Yatirim", "KRE"],
      ["Kule Yet. Mues.", "WCT"],
      ["Kuveyt Turk", "KTK"],
      ["Marbas", "MRS"],
      ["Meksa", "MSA"],
      ["Menekse Yet. Mues.", "WCE"],
      ["Merkez Menkul", "MRZ"],
      ["Merkez Yet. Mues.", "WAY"],
      ["Metro", "MTY"],
      ["Midas", "MDS"],
      ["Mkk Clearance System", "MKK"],
      ["Morgan Stanley", "MSI"],
      ["Mufg Bank", "MUF"],
      ["Muhafiz Kiy. Mad.", "VAQ"],
      ["Nadir Doviz Kiy. Mad. Yet. Mues.", "WBA"],
      ["NCM Investment", "NOR"],
      ["Neta Yatirim", "NTA"],
      ["Nil Kiy. Mad.", "WZR"],
      ["Nizip Doviz Kiy. Mad. Ticareti Yet. Mues.", "WCL"],
      ["Nmglobal Kiy. Mad.", "VAL"],
      ["Nurol Menkul", "NUR"],
      ["Nurol Yatirim Bankasi", "NYB"],
      ["Odea Bank", "ODB"],
      ["Oner Menkul", "ONR"],
      ["Osmanli", "OMD"],
      ["Osmanli Doviz Altin Yet. Mues.", "WTQ"],
      ["Osmanli Kiy. Mad.", "WSX"],
      ["Oyak", "OYA"],
      ["Ozak Kiy. Mad.", "VAB"],
      ["Papara", "POL"],
      ["Pasha Yatirim Bankasi", "PBT"],
      ["Phillipcapital", "PHC"],
      ["Piramit", "PIT"],
      ["Pirim Menkul", "PMK"],
      ["Pmd Official Auction Buyin", "XPD"],
      ["Prv Market Participant", "_PRV"],
      ["Qnb Finans", "FNY"],
      ["Raymond James", "RJS"],
      ["Renaissance Capital", "REN"],
      ["Rona Doviz Kiy. Mad. Yet. Mues.", "WAB"],
      ["Royal Bank Of Scotland", "RBS"],
      ["Saglamoglu Yet. Mues.", "VAN"],
      ["Saxo Capital", "SAX"],
      ["Say Kiy. Mad.", "WVQ"],
      ["Sayilgan Menkul", "SYG"],
      ["Seker", "SKY"],
      ["Sekerbank", "SEK"],
      ["Sembol Yabanci Para Kiy. Mad. Yet. Mues.", "WAF"],
      ["Soymen Menkul", "SOE"],
      ["Standart Kiy. Mad.", "WNN"],
      ["Strateji", "STJ"],
      ["T.Kalkinma Ve Yatirim Bankasi", "KLN"],
      ["Tacirler", "TAC"],
      ["Takasbank", "TVS"],
      ["Takasbank Collateral Valuation", "TTT"],
      ["Takasbank In Sukuk Repo", "XTK"],
      ["Takasbank Swap", "TVSSW"],
      ["Taksim Menkul", "TKS"],
      ["TCMB", "MB"],
      ["TEB", "TEB"],
      ["TEB (Menkul Kiymet Hizmetleri)", "TYS"],
      ["TEB (Portfoy Saklama)", "TEP"],
      ["TEB Yatirim", "TBY"],
      ["Tekstil Bankasi", "TES"],
      ["Tera", "TRA"],
      ["TFG Istanbul", "TFG"],
      ["Toros Menkul", "TOR"],
      ["Trader Kiy. Mad.", "VAE"],
      ["Trive", "TVM"],
      ["Troy Kiy. Mad.", "WAK"],
      ["TSKB", "TSK"],
      ["Turan Kiy. Mad.", "WAL"],
      ["Turkish", "TKY"],
      ["Turkish", "TMD"],
      ["Turkish Bank", "TBA"],
      ["Turkiye Emlak", "EKT"],
      ["Turkiye Finans", "FKT"],
      ["Turkland Bank", "TBN"],
      ["UBS Menkul", "UBS"],
      ["Uguras Kiy. Mad.", "WBN"],
      ["Unicredit", "UCI"],
      ["Unlu", "UNS"],
      ["Urus Kiy. Mad.", "WEW"],
      ["Vakif", "VKY"],
      ["Vakif Katilim Bankasi", "VKF"],
      ["Vakiflar Bankasi", "TVB"],
      ["Vakiflar Bankasi (Portfoy Saklama)", "VBS"],
      ["Venbey", "VNB"],
      ["Vesta Menkul", "VST"],
      ["XXX Menkul Kiymetler", "XXX"],
      ["Yapi Kredi", "YKR"],
      ["Yapi Kredi Bankasi", "YKB"],
      ["Yapi Kredi Bankasi (Portfoy Saklama)", "YKC"],
      ["Yatirim Fonlari", "XYF"],
      ["Yatirim Ortakliklari", "XYO"],
      ["YFAS", "YAT"],
      ["Yildiz Doviz Yet. Mues.", "WBO"],
      ["YTM", "YTM"],
      ["Ziraat", "ZRY"],
      ["Ziraat Bankasi", "TCZ"],
      ["Ziraat Bankasi (Portfoy Saklama)", "ZBS"],
      ["Ziraat Katilim Bankasi", "ZTB"]
    ];
  
    const [listebu, setlistebu] = useState([]);
  
    const [hisveri, sethisveri] = useState([]);

    const [fveri, setfveri] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const wsRef = useRef(null);
   
    const fetchStockPrice = useCallback(() => {
  

        if (urlsi) {
          const url = 'wss://'+urlsi+':443';
          const tut = []
          wsRef.current = new WebSocket(url);
        
          wsRef.current.onopen = () => {
            wsRef.current.send(`anlik,${secili}`);
          };
        
          wsRef.current.onmessage = (event) => {
            try {
              const data = JSON.parse(event.data);
              tut.push(data[0].last,data[0].ask,data[0].dailyAmount,data[0].dailyVolume,data[0].dailyChangePercentage,data[0].dailyLowest,data[0].dailyHighest);
        
              setfveri(tut)
        
            } catch (e) {
            } finally {
            }
          };
        
          wsRef.current.onerror = (error) => {
          };
        
          wsRef.current.onclose = (event) => {
          };
        }
      
      
      
      
      }, [])
      



  return (
    <div className={styles.takaswindow}>
      <div className={styles.sectionParent}>
        
        <div className={styles.search}>
          <div className={styles.bg} />
          <input
  type="text"
  value={aranan}
  onChange={(e) => his_ara(e.target.value, isimleri)}
  placeholder={"Hisse Ara"}
  className={`${styles.text1}`}
  autoComplete="off"
  maxLength={6}
/>          <img
            className={styles.vuesaxtwotonesearchNormalIcon}
            alt=""
            src="/vuesaxtwotonesearchnormal.svg"
          />
        </div>
        <div className={styles.frameContainer}>

        {aramalistt.length > 0 && (

          <div className={styles.textParent}>
            <div className={styles.text2}>Arama Sonuçları ({aramalistt.length})</div>
            <div className={styles.frameParent}>
            {aramalistt.map((key, index) => (
                <div className={styles.myfinansLogo31Parent} onClick={(e) => { e.stopPropagation(); fetchStockdata(key,formatDate(date),urlsi); }}>

              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${key || '/myfinanslogo3-1@2x'}.png`}
                  />
                <div className={styles.groupDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.textWrapper}>
                      <div className={styles.alclar}>{key}</div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            ))}
            </div>
          </div>
        )}

        </div>

        {fveri.length >= 1 && (

        <div className={styles.frameGroup}>
          <div className={styles.textParagraphParent}>
            <div className={styles.textParagraph}>{secili}</div>
            <b className={styles.b}>{fveri[0].toFixed(2)} ₺</b>
            <div className={styles.div}>%{fveri[5].toFixed(2)}</div>
          </div>
          <div className={styles.al2800Container}>
            <p className={styles.gnI}>Açılış : {hisveri.acilis || "-"}</p>
            <p className={styles.gnI}>Gün İçi : ₺{fveri[5]} - ₺{fveri[6]}</p>
            <p className={styles.gnI}>Teorik Eşleşme : ₺{fveri[1]} </p>
            <p className={styles.gnI}>İşlem Adeti : {Intl.NumberFormat('en-US').format(fveri[2])}</p>
            <p className={styles.gnI}>İşlem Hacmi : ₺{Intl.NumberFormat('en-US').format(fveri[3])}</p>
          </div>
        </div>
        )}


        <div className={styles.frameParent1}>
        <div className={styles.frameParent2}>
  {['Günlük', 'Haftalık', 'Aylık', '3 Aylık'].map((text, index) => (
    <div
      key={index}
      onClick={() => aradegis(index)}
      className={aralik === index ? styles.gnlkWrapper : styles.haftalkWrapper}
      style={{ cursor: 'pointer' }}
    >
      <div className={aralik === index ? styles.gnlk : styles.haftalk}>
        {text}
      </div>
    </div>
  ))}
</div>
          <div className={styles.frameParent3}>
            <div 
              className={hangi === 0 ? `${styles.alclarWrapper}` : styles.satclarWrapper}
              onClick={() => sethangi(0)}
              style={{cursor: 'pointer'}}
            >
              <div className={hangi === 0 ? styles.alclar : styles.satclar}>Alıcılar</div>
            </div>
            <div 
              className={hangi === 1 ? `${styles.alclarWrapper}` : styles.satclarWrapper}
              onClick={() => sethangi(1)}
              style={{cursor: 'pointer'}}
            >
              <div className={hangi === 1 ? styles.alclar : styles.satclar}>Satıcılar</div>
            </div>
            <div 
              className={hangi === 2 ? `${styles.alclarWrapper}` : styles.satclarWrapper}
              onClick={() => sethangi(2)}
              style={{cursor: 'pointer'}}
            >
              <div className={hangi === 2 ? styles.alclar : styles.satclar}>Saklama</div>
            </div>
          </div>
          <div className={styles.degisim}>
            <div className={styles.degisimInner}>
              <div className={styles.frameParent4}>
                <div className={styles.budgetParent}>
                  <div className={styles.budget}>Kurum</div>
                  <div className={styles.budget1}>Lot</div>
                  <div className={styles.budget2}>%</div>
                  <div className={styles.budget3}>Maliyet</div>
                </div>
                <div style={{
        maxHeight: '350px', 
        overflowY: 'auto', 
        overflowX: 'hidden',
        width: '100%',
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
      }}>
        <div style={{
          overflowY: 'scroll',
          height: '100%',
          paddingRight: '20px',
          boxSizing: 'content-box',
          width: '100%'
        }}>
          {/* Alıcılar */}
          {hangi === 0 && listebu && listebu.length > 0 && listebu.map((key, index) => {
            const farkValue = aralik === 0 ? key.dd : aralik === 1 ? key.wd : aralik === 2 ? key.md : key.qd;
            return farkValue >= 0 ? (
              <div key={index} className={styles.frameParent5}>
                <div className={styles.budgetGroup} style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <div className={styles.budget4} style={{width: '25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{getFullName((key.rc).slice(0, -2))}</div>
                  <div className={styles.budget5} style={{width: '25%', textAlign: 'right'}}>{(Intl.NumberFormat('en-US').format((key.a).toFixed(0)))}</div>
                  <div className={styles.budget6} style={{width: '25%', textAlign: 'right'}}>{((parseFloat(key.a)/parseFloat(toplamadet))*100).toFixed(2)}</div>
                  <div className={styles.budget7} style={{width: '25%', textAlign: 'right'}}>{Intl.NumberFormat('en-US').format(farkValue)}</div>
                </div>
              </div>
            ) : null;
          })}

          {/* Satıcılar */}
          {hangi === 1 && listebu && listebu.length > 0 && listebu.map((key, index) => {
            const farkValue = aralik === 0 ? key.dd : aralik === 1 ? key.wd : aralik === 2 ? key.md : key.qd;
            return farkValue < 0 ? (
              <div key={index} className={styles.frameParent5}>
                <div className={styles.budgetGroup} style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <div className={styles.budget4} style={{width: '25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{getFullName((key.rc).slice(0, -2))}</div>
                  <div className={styles.budget5} style={{width: '25%', textAlign: 'right'}}>{(Intl.NumberFormat('en-US').format((key.a).toFixed(0)))}</div>
                  <div className={styles.budget6} style={{width: '25%', textAlign: 'right'}}>{((parseFloat(key.a)/parseFloat(toplamadet))*100).toFixed(2)}</div>
                  <div className={styles.budget7} style={{width: '25%', textAlign: 'right'}}>{Intl.NumberFormat('en-US').format(Math.abs(farkValue))}</div>
                </div>
              </div>
            ) : null;
          })}

          {/* Saklama Dağılımı */}
          {hangi === 2 && listebu && listebu.length > 0 && listebu.map((key, index) => (
            <div key={index} className={styles.frameParent5}>
              <div className={styles.budgetGroup} style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <div className={styles.budget4} style={{width: '25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{getFullName((key.rc).slice(0, -2))}</div>
                <div className={styles.budget5} style={{width: '25%', textAlign: 'right'}}>{(Intl.NumberFormat('en-US').format((key.a).toFixed(0)))}</div>
                <div className={styles.budget6} style={{width: '25%', textAlign: 'right'}}>{((parseFloat(key.a)/parseFloat(toplamadet))*100).toFixed(2)}</div>
                <div className={styles.budget7} style={{width: '25%', textAlign: 'right'}}>
                  {aralik === 0 ? Intl.NumberFormat('en-US').format(key.dd) :
                   aralik === 1 ? Intl.NumberFormat('en-US').format(key.wd) :
                   aralik === 2 ? Intl.NumberFormat('en-US').format(key.md) :
                   Intl.NumberFormat('en-US').format(key.qd)}
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakasWindow;
