import PropTypes from "prop-types";
import styles from "./Select.module.css";


import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, orderBy, limit, getDocs, doc, getDoc, serverTimestamp, setDoc, updateDoc, increment, onSnapshot, writeBatch, Timestamp } from "firebase/firestore";
import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useNavigate } from 'react-router-dom'; // Bu satırı ekleyin
import { debounce } from 'lodash';

const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };


  

const Select = ({ className = "" }) => {
    
    const [stockNames, setStockNames] = useState([]);
    const [sirketadstam, setSirketAdstam] = useState([
        "A1CAP",
        "ACSEL",
        "ADEL",
        "ADESE",
        "ADGYO",
        "AEFES",
        "AFYON",
        "AGESA",
        "AGHOL",
        "AGROT",
        "AGYO",
        "AHGAZ",
        "AKBNK",
        "AKCNS",
        "AKENR",
        "AKFGY",
        "AKFYE",
        "AKGRT",
        "AKMGY",
        "AKSA",
        "AKSEN",
        "AKSGY",
        "AKSUE",
        "AKYHO",
        "ALARK",
        "ALBRK",
        "ALCAR",
        "ALCTL",
        "ALFAS",
        "ALGYO",
        "ALKA",
        "ALKIM",
        "ALKLC",
        "ALMAD",
        "ALTINS1",
        "ALTNY",
        "ALVES",
        "ANELE",
        "ANGEN",
        "ANHYT",
        "ANSGR",
        "ARASE",
        "ARCLK",
        "ARDYZ",
        "ARENA",
        "ARSAN",
        "ARTMS",
        "ARZUM",
        "ASELS",
        "ASGYO",
        "ASTOR",
        "ASUZU",
        "ATAGY",
        "ATAKP",
        "ATATP",
        "ATEKS",
        "ATLAS",
        "ATSYH",
        "AVGYO",
        "AVHOL",
        "AVOD",
        "AVPGY",
        "AVTUR",
        "AYCES",
        "AYDEM",
        "AYEN",
        "AYES",
        "AYGAZ",
        "AZTEK",
        "BAGFS",
        "BAKAB",
        "BALAT",
        "BANVT",
        "BARMA",
        "BASCM",
        "BASGZ",
        "BAYRK",
        "BEGYO",
        "BERA",
        "BEYAZ",
        "BFREN",
        "BIENY",
        "BIGCH",
        "BIMAS",
        "BINHO",
        "BIOEN",
        "BIZIM",
        "BJKAS",
        "BLCYT",
        "BMSCH",
        "BMSTL",
        "BNTAS",
        "BOBET",
        "BORLS",
        "BORSK",
        "BOSSA",
        "BRISA",
        "BRKO",
        "BRKSN",
        "BRKVY",
        "BRLSM",
        "BRMEN",
        "BRSAN",
        "BRYAT",
        "BSOKE",
        "BTCIM",
        "BUCIM",
        "BURCE",
        "BURVA",
        "BVSAN",
        "BYDNR",
        "CANTE",
        "CASA",
        "CATES",
        "CCOLA",
        "CELHA",
        "CEMAS",
        "CEMTS",
        "CEOEM",
        "CIMSA",
        "CLEBI",
        "CMBTN",
        "CMENT",
        "CONSE",
        "COSMO",
        "CRDFA",
        "CRFSA",
        "CUSAN",
        "CVKMD",
        "CWENE",
        "DAGHL",
        "DAGI",
        "DAPGM",
        "DARDL",
        "DENGE",
        "DERHL",
        "DERIM",
        "DESA",
        "DESPC",
        "DEVA",
        "DGATE",
        "DGGYO",
        "DGNMO",
        "DIRIT",
        "DITAS",
        "DMRGD",
        "DMSAS",
        "DNISI",
        "DOAS",
        "DOBUR",
        "DOCO",
        "DOFER",
        "DOGUB",
        "DOHOL",
        "DOKTA",
        "DURDO",
        "DYOBY",
        "DZGYO",
        "EBEBK",
        "ECILC",
        "ECZYT",
        "EDATA",
        "EDIP",
        "EFORC",
        "EGEEN",
        "EGEPO",
        "EGGUB",
        "EGPRO",
        "EGSER",
        "EKGYO",
        "EKIZ",
        "EKOS",
        "EKSUN",
        "ELITE",
        "EMKEL",
        "EMNIS",
        "ENERY",
        "ENJSA",
        "ENKAI",
        "ENSRI",
        "ENTRA",
        "EPLAS",
        "ERBOS",
        "ERCB",
        "EREGL",
        "ERSU",
        "ESCAR",
        "ESCOM",
        "ESEN",
        "ETILR",
        "ETYAT",
        "EUHOL",
        "EUKYO",
        "EUPWR",
        "EUREN",
        "EUYO",
        "EYGYO",
        "FADE",
        "FENER",
        "FLAP",
        "FMIZP",
        "FONET",
        "FORMT",
        "FORTE",
        "FRIGO",
        "FROTO",
        "FZLGY",
        "GARAN",
        "GARFA",
        "GEDIK",
        "GEDZA",
        "GENIL",
        "GENTS",
        "GEREL",
        "GESAN",
        "GIPTA",
        "GLBMD",
        "GLCVY",
        "GLRYH",
        "GLYHO",
        "GMTAS",
        "GOKNR",
        "GOLTS",
        "GOODY",
        "GOZDE",
        "GRNYO",
        "GRSEL",
        "GRTRK",
        "GSDDE",
        "GSDHO",
        "GSRAY",
        "GUBRF",
        "GWIND",
        "GZNMI",
        "HALKB",
        "HATEK",
        "HATSN",
        "HDFGS",
        "HEDEF",
        "HEKTS",
        "HKTM",
        "HLGYO",
        "HOROZ",
        "HRKET",
        "HTTBT",
        "HUBVC",
        "HUNER",
        "HURGZ",
        "ICBCT",
        "ICUGS",
        "IDGYO",
        "IEYHO",
        "IHAAS",
        "IHEVA",
        "IHGZT",
        "IHLAS",
        "IHLGM",
        "IHYAY",
        "IMASM",
        "INDES",
        "INFO",
        "INGRM",
        "INTEM",
        "INVEO",
        "INVES",
        "IPEKE",
        "ISATR",
        "ISBIR",
        "ISBTR",
        "ISCTR",
        "ISDMR",
        "ISFIN",
        "ISGSY",
        "ISGYO",
        "ISKPL",
        "ISKUR",
        "ISMEN",
        "ISSEN",
        "ISYAT",
        "IZENR",
        "IZFAS",
        "IZINV",
        "IZMDC",
        "JANTS",
        "KAPLM",
        "KAREL",
        "KARSN",
        "KARTN",
        "KARYE",
        "KATMR",
        "KAYSE",
        "KBORU",
        "KCAER",
        "KCHOL",
        "KENT",
        "KERVN",
        "KERVT",
        "KFEIN",
        "KGYO",
        "KIMMR",
        "KLGYO",
        "KLKIM",
        "KLMSN",
        "KLNMA",
        "KLRHO",
        "KLSER",
        "KLSYN",
        "KMPUR",
        "KNFRT",
        "KOCMT",
        "KONKA",
        "KONTR",
        "KONTRR",
        "KONYA",
        "KOPOL",
        "KORDS",
        "KOTON",
        "KOZAA",
        "KOZAL",
        "KRDMA",
        "KRDMB",
        "KRDMD",
        "KRGYO",
        "KRONT",
        "KRPLS",
        "KRSTL",
        "KRTEK",
        "KRVGD",
        "KSTUR",
        "KTLEV",
        "KTSKR",
        "KUTPO",
        "KUVVA",
        "KUYAS",
        "KZBGY",
        "KZGYO",
        "LIDER",
        "LIDFA",
        "LILAK",
        "LINK",
        "LKMNH",
        "LMKDC",
        "LOGO",
        "LRSHO",
        "LUKSK",
        "MAALT",
        "MACKO",
        "MAGEN",
        "MAKIM",
        "MAKTK",
        "MANAS",
        "MARBL",
        "MARKA",
        "MARTI",
        "MAVI",
        "MEDTR",
        "MEGAP",
        "MEGMT",
        "MEKAG",
        "MEPET",
        "MERCN",
        "MERIT",
        "MERKO",
        "METRO",
        "METUR",
        "MGROS",
        "MHRGY",
        "MIATK",
        "MIPAZ",
        "MMCAS",
        "MNDRS",
        "MNDTR",
        "MOBTL",
        "MOGAN",
        "MPARK",
        "MRGYO",
        "MRSHL",
        "MSGYO",
        "MTRKS",
        "MTRYO",
        "MZHLD",
        "NATEN",
        "NETAS",
        "NIBAS",
        "NTGAZ",
        "NTHOL",
        "NUGYO",
        "NUHCM",
        "OBAMS",
        "OBASE",
        "ODAS",
        "ODINE",
        "OFSYM",
        "ONCSM",
        "ONRYT",
        "ORCAY",
        "ORGE",
        "ORMA",
        "OSMEN",
        "OSTIM",
        "OTKAR",
        "OTTO",
        "OYAKC",
        "OYAYO",
        "OYLUM",
        "OYYAT",
        "OZGYO",
        "OZKGY",
        "OZRDN",
        "OZSUB",
        "OZYSR",
        "PAGYO",
        "PAMEL",
        "PAPIL",
        "PARSN",
        "PASEU",
        "PATEK",
        "PCILT",
        "PEHOL",
        "PEKGY",
        "PENGD",
        "PENTA",
        "PETKM",
        "PETUN",
        "PGSUS",
        "PINSU",
        "PKART",
        "PKENT",
        "PLTUR",
        "PNLSN",
        "PNSUT",
        "POLHO",
        "POLTK",
        "PRDGS",
        "PRKAB",
        "PRKME",
        "PRZMA",
        "PSDTC",
        "PSGYO",
        "QNBFB",
        "QNBFL",
        "QUAGR",
        "RALYH",
        "RAYSG",
        "REEDR",
        "RGYAS",
        "RNPOL",
        "RODRG",
        "ROYAL",
        "RTALB",
        "RUBNS",
        "RYGYO",
        "RYSAS",
        "SAFKR",
        "SAHOL",
        "SAMAT",
        "SANEL",
        "SANFM",
        "SANKO",
        "SARKY",
        "SASA",
        "SAYAS",
        "SDTTR",
        "SEGMN",
        "SEGYO",
        "SEKFK",
        "SEKUR",
        "SELEC",
        "SELGD",
        "SELVA",
        "SEYKM",
        "SILVR",
        "SISE",
        "SKBNK",
        "SKTAS",
        "SKYLP",
        "SKYMD",
        "SMART",
        "SMRTG",
        "SNGYO",
        "SNICA",
        "SNKRN",
        "SNPAM",
        "SODSN",
        "SOKE",
        "SOKM",
        "SONME",
        "SRVGY",
        "SUMAS",
        "SUNTK",
        "SURGY",
        "SUWEN",
        "TABGD",
        "TARKM",
        "TATEN",
        "TATGD",
        "TAVHL",
        "TBORG",
        "TCELL",
        "TDGYO",
        "TEKTU",
        "TERA",
        "TETMT",
        "TEZOL",
        "TGSAS",
        "THYAO",
        "TKFEN",
        "TKNSA",
        "TLMAN",
        "TMPOL",
        "TMSN",
        "TNZTP",
        "TOASO",
        "TRCAS",
        "TRGYO",
        "TRILC",
        "TSGYO",
        "TSKB",
        "TSPOR",
        "TTKOM",
        "TTRAK",
        "TUCLK",
        "TUKAS",
        "TUPRS",
        "TUREX",
        "TURGG",
        "TURSG",
        "UFUK",
        "ULAS",
        "ULKER",
        "ULUFA",
        "ULUSE",
        "ULUUN",
        "UMPAS",
        "UNLU",
        "USAK",
        "UZERB",
        "VAKBN",
        "VAKFN",
        "VAKKO",
        "VANGD",
        "VBTYZ",
        "VERTU",
        "VERUS",
        "VESBE",
        "VESTL",
        "VKFYO",
        "VKGYO",
        "VKING",
        "VRGYO",
        "YAPRK",
        "YATAS",
        "YAYLA",
        "YBTAS",
        "YEOTK",
        "YESIL",
        "YGGYO",
        "YGYO",
        "YIGIT",
        "YKBNK",
        "YKSLN",
        "YONGA",
        "YUNSA",
        "YYAPI",
        "YYLGD",
        "ZEDUR",
        "ZOREN",
        "ZRGYO"
    ]
      );
  
      const [urlsi, setUrlsi] = useState('investcodecompany.online');

      useEffect(() => {
        setStockNames(sirketadstam);
      }, [sirketadstam]);
    

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
  
    const textInputRef = useRef(null);
    const [arama, setArama] = useState('');
    const [aramalistt, setaramalistt] = useState([]);

    const [aranan, setaranan] = useState('');


  const fetchStockPrice = (hisse,urls) => {
    return new Promise((resolve, reject) => {
      const url = 'wss://'+urls+':443';
      
      const ws = new WebSocket(url);
      
      ws.onopen = () => {
        ws.send(`anlik,${hisse}`);
      };
      
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          resolve(data[0]);
        } catch (e) {
          console.error('JSON parse error:', e);
          reject(e);
        } finally {
          ws.close();
        }
      };
      
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };
      
      ws.onclose = (event) => {
      };
  
      setTimeout(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          reject(new Error('WebSocket connection timeout'));
        }
      }, 10000);
    });
  };



    const [yedek, setyedek] = useState([
      "A1CAP",
      "ACSEL",
      "ADEL",
      "ADESE",
      "ADGYO",
      "AEFES",
      "AFYON",
      "AGESA",
      "AGHOL",
      "AGROT",
      "AGYO",
      "AHGAZ",
      "AKBNK",
      "AKCNS",
      "AKENR",
      "AKFGY",
      "AKFYE",
      "AKGRT",
      "AKMGY",
      "AKSA",
      "AKSEN",
      "AKSGY",
      "AKSUE",
      "AKYHO",
      "ALARK",
      "ALBRK",
      "ALCAR",
      "ALCTL",
      "ALFAS",
      "ALGYO",
      "ALKA",
      "ALKIM",
      "ALKLC",
      "ALMAD",
      "ALTINS1",
      "ALTNY",
      "ALVES",
      "ANELE",
      "ANGEN",
      "ANHYT",
      "ANSGR",
      "ARASE",
      "ARCLK",
      "ARDYZ",
      "ARENA",
      "ARSAN",
      "ARTMS",
      "ARZUM",
      "ASELS",
      "ASGYO",
      "ASTOR",
      "ASUZU",
      "ATAGY",
      "ATAKP",
      "ATATP",
      "ATEKS",
      "ATLAS",
      "ATSYH",
      "AVGYO",
      "AVHOL",
      "AVOD",
      "AVPGY",
      "AVTUR",
      "AYCES",
      "AYDEM",
      "AYEN",
      "AYES",
      "AYGAZ",
      "AZTEK",
      "BAGFS",
      "BAKAB",
      "BALAT",
      "BANVT",
      "BARMA",
      "BASCM",
      "BASGZ",
      "BAYRK",
      "BEGYO",
      "BERA",
      "BEYAZ",
      "BFREN",
      "BIENY",
      "BIGCH",
      "BIMAS",
      "BINHO",
      "BIOEN",
      "BIZIM",
      "BJKAS",
      "BLCYT",
      "BMSCH",
      "BMSTL",
      "BNTAS",
      "BOBET",
      "BORLS",
      "BORSK",
      "BOSSA",
      "BRISA",
      "BRKO",
      "BRKSN",
      "BRKVY",
      "BRLSM",
      "BRMEN",
      "BRSAN",
      "BRYAT",
      "BSOKE",
      "BTCIM",
      "BUCIM",
      "BURCE",
      "BURVA",
      "BVSAN",
      "BYDNR",
      "CANTE",
      "CASA",
      "CATES",
      "CCOLA",
      "CELHA",
      "CEMAS",
      "CEMTS",
      "CEOEM",
      "CIMSA",
      "CLEBI",
      "CMBTN",
      "CMENT",
      "CONSE",
      "CONSER",
      "COSMO",
      "CRDFA",
      "CRFSA",
      "CUSAN",
      "CVKMD",
      "CWENE",
      "DAGHL",
      "DAGI",
      "DAPGM",
      "DARDL",
      "DCTTR",
      "DENGE",
      "DERHL",
      "DERIM",
      "DESA",
      "DESPC",
      "DEVA",
      "DGATE",
      "DGGYO",
      "DGNMO",
      "DIRIT",
      "DITAS",
      "DMRGD",
      "DMSAS",
      "DNISI",
      "DOAS",
      "DOBUR",
      "DOCO",
      "DOFER",
      "DOGUB",
      "DOHOL",
      "DOKTA",
      "DURDO",
      "DYOBY",
      "DZGYO",
      "EBEBK",
      "ECILC",
      "ECZYT",
      "EDATA",
      "EDIP",
      "EFORC",
      "EGEEN",
      "EGEPO",
      "EGGUB",
      "EGPRO",
      "EGSER",
      "EKGYO",
      "EKIZ",
      "EKOS",
      "EKSUN",
      "ELITE",
      "EMKEL",
      "EMNIS",
      "ENERY",
      "ENJSA",
      "ENKAI",
      "ENSRI",
      "ENTRA",
      "EPLAS",
      "ERBOS",
      "ERCB",
      "EREGL",
      "ERSU",
      "ESCAR",
      "ESCOM",
      "ESEN",
      "ETILR",
      "ETYAT",
      "EUHOL",
      "EUKYO",
      "EUPWR",
      "EUREN",
      "EUYO",
      "EYGYO",
      "FADE",
      "FENER",
      "FLAP",
      "FMIZP",
      "FONET",
      "FORMT",
      "FORTE",
      "FRIGO",
      "FROTO",
      "FZLGY",
      "GARAN",
      "GARFA",
      "GEDIK",
      "GEDZA",
      "GENIL",
      "GENTS",
      "GEREL",
      "GESAN",
      "GIPTA",
      "GLBMD",
      "GLCVY",
      "GLRYH",
      "GLYHO",
      "GMTAS",
      "GOKNR",
      "GOLTS",
      "GOODY",
      "GOZDE",
      "GRNYO",
      "GRSEL",
      "GRTRK",
      "GSDDE",
      "GSDHO",
      "GSRAY",
      "GUBRF",
      "GWIND",
      "GZNMI",
      "HALKB",
      "HATEK",
      "HATSN",
      "HDFGS",
      "HEDEF",
      "HEKTS",
      "HKTM",
      "HLGYO",
      "HOROZ",
      "HRKET",
      "HTTBT",
      "HUBVC",
      "HUNER",
      "HURGZ",
      "ICBCT",
      "ICUGS",
      "IDGYO",
      "IEYHO",
      "IHAAS",
      "IHEVA",
      "IHGZT",
      "IHLAS",
      "IHLGM",
      "IHYAY",
      "IMASM",
      "INDES",
      "INFO",
      "INGRM",
      "INTEM",
      "INVEO",
      "INVES",
      "IPEKE",
      "ISATR",
      "ISBIR",
      "ISBTR",
      "ISCTR",
      "ISDMR",
      "ISFIN",
      "ISGSY",
      "ISGYO",
      "ISKPL",
      "ISKUR",
      "ISMEN",
      "ISSEN",
      "ISYAT",
      "IZENR",
      "IZFAS",
      "IZINV",
      "IZMDC",
      "JANTS",
      "KAPLM",
      "KAREL",
      "KARSN",
      "KARTN",
      "KARYE",
      "KATMR",
      "KAYSE",
      "KBORU",
      "KCAER",
      "KCHOL",
      "KENT",
      "KERVN",
      "KERVT",
      "KFEIN",
      "KGYO",
      "KIMMR",
      "KLGYO",
      "KLKIM",
      "KLMSN",
      "KLNMA",
      "KLRHO",
      "KLSER",
      "KLSYN",
      "KMPUR",
      "KNFRT",
      "KOCMT",
      "KONKA",
      "KONTR",
      "KONYA",
      "KOPOL",
      "KORDS",
      "KOTON",
      "KOZAA",
      "KOZAL",
      "KRDMA",
      "KRDMB",
      "KRDMD",
      "KRGYO",
      "KRONT",
      "KRPLS",
      "KRSTL",
      "KRTEK",
      "KRVGD",
      "KSTUR",
      "KTLEV",
      "KTSKR",
      "KUTPO",
      "KUVVA",
      "KUYAS",
      "KZBGY",
      "KZGYO",
      "LIDER",
      "LIDFA",
      "LILAK",
      "LINK",
      "LKMNH",
      "LMKDC",
      "LOGO",
      "LRSHO",
      "LUKSK",
      "MAALT",
      "MACKO",
      "MAGEN",
      "MAKIM",
      "MAKTK",
      "MANAS",
      "MARBL",
      "MARKA",
      "MARTI",
      "MAVI",
      "MEDTR",
      "MEGAP",
      "MEGMT",
      "MEKAG",
      "MEPET",
      "MERCN",
      "MERIT",
      "MERKO",
      "METRO",
      "METUR",
      "MGROS",
      "MHRGY",
      "MIATK",
      "MIPAZ",
      "MMCAS",
      "MNDRS",
      "MNDTR",
      "MOBTL",
      "MOGAN",
      "MPARK",
      "MRGYO",
      "MRSHL",
      "MSGYO",
      "MTRKS",
      "MTRYO",
      "MZHLD",
      "NATEN",
      "NETAS",
      "NIBAS",
      "NTGAZ",
      "NTHOL",
      "NUGYO",
      "NUHCM",
      "OBAMS",
      "OBASE",
      "ODAS",
      "ODINE",
      "OFSYM",
      "ONCSM",
      "ONRYT",
      "ORCAY",
      "ORGE",
      "ORMA",
      "OSMEN",
      "OSTIM",
      "OTKAR",
      "OTTO",
      "OYAKC",
      "OYAYO",
      "OYLUM",
      "OYYAT",
      "OZGYO",
      "OZKGY",
      "OZRDN",
      "OZSUB",
      "OZYSR",
      "PAGYO",
      "PAMEL",
      "PAPIL",
      "PARSN",
      "PASEU",
      "PATEK",
      "PCILT",
      "PEHOL",
      "PEKGY",
      "PENGD",
      "PENTA",
      "PETKM",
      "PETUN",
      "PGSUS",
      "PINSU",
      "PKART",
      "PKENT",
      "PLTUR",
      "PNLSN",
      "PNSUT",
      "POLHO",
      "POLTK",
      "PRDGS",
      "PRKAB",
      "PRKME",
      "PRZMA",
      "PSDTC",
      "PSGYO",
      "QNBFB",
      "QNBFL",
      "QUAGR",
      "RALYH",
      "RAYSG",
      "REEDR",
      "RGYAS",
      "RNPOL",
      "RODRG",
      "ROYAL",
      "RTALB",
      "RUBNS",
      "RYGYO",
      "RYSAS",
      "SAFKR",
      "SAHOL",
      "SAMAT",
      "SANEL",
      "SANFM",
      "SANKO",
      "SARKY",
      "SASA",
      "SAYAS",
      "SDTTR",
      "SEGMN",
      "SEGYO",
      "SEKFK",
      "SEKUR",
      "SELEC",
      "SELGD",
      "SELVA",
      "SEYKM",
      "SILVR",
      "SISE",
      "SKBNK",
      "SKTAS",
      "SKYLP",
      "SKYMD",
      "SMART",
      "SMRTG",
      "SNGYO",
      "SNICA",
      "SNKRN",
      "SNPAM",
      "SODSN",
      "SOKE",
      "SOKM",
      "SONME",
      "SRVGY",
      "SUMAS",
      "SUNTK",
      "SURGY",
      "SUWEN",
      "TABGD",
      "TARKM",
      "TATEN",
      "TATGD",
      "TAVHL",
      "TBORG",
      "TCELL",
      "TDGYO",
      "TEKTU",
      "TERA",
      "TETMT",
      "TEZOL",
      "TGSAS",
      "THYAO",
      "TKFEN",
      "TKNSA",
      "TLMAN",
      "TMPOL",
      "TMSN",
      "TNZTP",
      "TOASO",
      "TRCAS",
      "TRGYO",
      "TRILC",
      "TSGYO",
      "TSKB",
      "TSPOR",
      "TTKOM",
      "TTRAK",
      "TUCLK",
      "TUKAS",
      "TUPRS",
      "TUREX",
      "TURGG",
      "TURSG",
      "UFUK",
      "ULAS",
      "ULKER",
      "ULUFA",
      "ULUSE",
      "ULUUN",
      "UMPAS",
      "UNLU",
      "USAK",
      "UZERB",
      "VAKBN",
      "VAKFN",
      "VAKKO",
      "VANGD",
      "VBTYZ",
      "VERTU",
      "VERUS",
      "VESBE",
      "VESTL",
      "VKFYO",
      "VKGYO",
      "VKING",
      "VRGYO",
      "YAPRK",
      "YATAS",
      "YAYLA",
      "YBTAS",
      "YEOTK",
      "YESIL",
      "YGGYO",
      "YGYO",
      "YIGIT",
      "YKBNK",
      "YKSLN",
      "YONGA",
      "YUNSA",
      "YYAPI",
      "YYLGD",
      "ZEDUR",
      "ZOREN",
      "ZRGYO"
    ]
    );
    const navigate = useNavigate(); // Bu satırı ekleyin

    // Arama geçmişini güncelle ve kaydet
  const updateRecentSearches = useCallback((symbol) => {
    setRecentSearches(prevSearches => {
      const updatedSearches = [symbol, ...prevSearches.filter(s => s !== symbol)].slice(0, 3);
      localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  }, []);


    const fetchStockNames = async () => {
      try {
        const response = await axios.get('https://finans.mynet.com/borsa/hisseler/', {
          headers: { 'User-Agent': 'Mozilla/5.0' } // Prevent potential blocking
        });
        const html = response.data;
    
        const root = parse(html);
        const rows = root.querySelectorAll('table tbody tr');
    
        const names = rows.map(row => {
          const nameElement = row.querySelector('td:first-child strong a');
          return nameElement ? nameElement.text.trim().split(' ')[0] : null;
        }).filter(Boolean);
    
        setSirketAdstam(names)
      } catch (error) {
        setSirketAdstam(yedek)
        console.error('Error fetching stock data:', error);
      }
    };
      // Hisse tıklama işleyicisi
  const handleHisseClick = useCallback((hisseKodu) => {
    updateRecentSearches(hisseKodu);
    navigate(`/derinlik/${hisseKodu}`);
  }, [navigate, updateRecentSearches]);


  const his_ara = useCallback(async (hisse) => {
    if (hisse.length < 1) {
      setaramalistt([]);
      return;
    }
  
    const upperHisse = hisse.toUpperCase();
    setArama(upperHisse);
  
    const arananHisseler = stockNames.filter(item => 
      item.toUpperCase().startsWith(upperHisse)
    ).slice(0, 10); // En fazla 10 sonuç göster
  
    const initialData = arananHisseler.map(item => ({
      name: item,
      fiyat: ["-", "-"],
    }));
  
    setaramalistt(initialData);

    const updatedData = await Promise.all(
      arananHisseler.map(async (item) => ({
        name: item,
        fiyat: await fetchStockPrice(item, urlsi),
      }))
    );
  
    setaramalistt(updatedData);
  }, [stockNames, fetchStockPrice, urlsi]);

    const wsRef = useRef(null);

    const debouncedSearch = useCallback(
        debounce((query) => his_ara(query), 300),
        [his_ara]
      );


    const [recentSearches, setRecentSearches] = useState([]);
    const [stockPrices, setStockPrices] = useState({});
    const [topLists, setTopLists] = useState({
      daily: [],
      weekly: [],
      monthly: []
    });
  
    useEffect(() => {
      const stocksCollection = collection(db, 'searchedStocks');
      
      const unsubscribeDaily = onSnapshot(
        query(stocksCollection, orderBy('dailyCount', 'desc'), limit(8)),
        (snapshot) => {
          const dailyList = snapshot.docs.map(doc => ({
            symbol: doc.data().symbol,
            count: doc.data().dailyCount
          }));
          setTopLists(prev => ({ ...prev, daily: dailyList }));
          fetchPricesForList(dailyList);
        }
      );
  
      const unsubscribeWeekly = onSnapshot(
        query(stocksCollection, orderBy('weeklyCount', 'desc'), limit(10)),
        (snapshot) => {
          const weeklyList = snapshot.docs.map(doc => ({
            symbol: doc.data().symbol,
            count: doc.data().weeklyCount
          }));
          setTopLists(prev => ({ ...prev, weekly: weeklyList }));
        }
      );
  
      const unsubscribeMonthly = onSnapshot(
        query(stocksCollection, orderBy('monthlyCount', 'desc'), limit(10)),
        (snapshot) => {
          const monthlyList = snapshot.docs.map(doc => ({
            symbol: doc.data().symbol,
            count: doc.data().monthlyCount
          }));
          setTopLists(prev => ({ ...prev, monthly: monthlyList }));
        }
      );
  
      // Check and reset counts if necessary
      checkAndResetCounts();
  
  
      return () => {
        unsubscribeDaily();
        unsubscribeWeekly();
        unsubscribeMonthly();
      };
    }, []);
  
    const checkAndResetCounts = useCallback(async () => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
      const thisWeek = getWeekStart(now).getTime();
      const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
      
      const metadataRef = doc(db, 'metadata', 'lastResets');
      const metadataDoc = await getDoc(metadataRef);
      
      if (!metadataDoc.exists()) {
        await setDoc(metadataRef, {
          daily: Timestamp.fromDate(now),
          weekly: Timestamp.fromDate(now),
          monthly: Timestamp.fromDate(now)
        });
        await resetAllCounts();
      } else {
        const data = metadataDoc.data();
        if (data.daily.toDate().getTime() < today) {
          await resetCounts('daily');
          await updateDoc(metadataRef, { daily: Timestamp.fromDate(now) });
        }
        if (data.weekly.toDate().getTime() < thisWeek) {
          await resetCounts('weekly');
          await updateDoc(metadataRef, { weekly: Timestamp.fromDate(now) });
        }
        if (data.monthly.toDate().getTime() < thisMonth) {
          await resetCounts('monthly');
          await updateDoc(metadataRef, { monthly: Timestamp.fromDate(now) });
        }
      }
    }, [db]);
  
    const getWeekStart = (date) => {
      const d = new Date(date);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    };
  
    useEffect(() => {
      const checkResets = async () => {
        await checkAndResetCounts();
      };
      checkResets();
  
      // Set up an interval to check for resets every hour
      const intervalId = setInterval(checkResets, 3600000); // 1 hour in milliseconds
  
      return () => clearInterval(intervalId);
    }, [checkAndResetCounts]);
  
    const resetAllCounts = useCallback(async () => {
      await resetCounts('daily');
      await resetCounts('weekly');
      await resetCounts('monthly');
    }, []);
  
    const resetCounts = useCallback(async (period) => {
      const stocksCollection = collection(db, 'searchedStocks');
      const snapshot = await getDocs(stocksCollection);
  
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        const docRef = doc.ref;
        batch.update(docRef, { [`${period}Count`]: 0 });
      });
  
      await batch.commit();
      console.log(`${period} counts reset`);
    }, [db]);
  
    const fetchPricesForList = async (stockList) => {
      const prices = {};

      for (const item of stockList) {
        try {
          prices[item.symbol] = await fetchStockPrice(item.symbol,urlsi);
        } catch (error) {
          console.error(`Error fetching price for ${item.symbol}:`, error);
          prices[item.symbol] = null;
        }
      }
      setStockPrices(prev => ({ ...prev, ...prices }));
    };
  
   
    const handleSearch = async (symbol) => {
      await checkAndResetCounts(); // Check and reset if necessary before incrementing
  
      const stockRef = doc(db, 'searchedStocks', symbol);
      const stockDoc = await getDoc(stockRef);
  
      if (stockDoc.exists()) {
        await updateDoc(stockRef, {
          dailyCount: increment(1),
          weeklyCount: increment(1),
          monthlyCount: increment(1)
        });
      } else {
        await setDoc(stockRef, {
          symbol,
          dailyCount: 1,
          weeklyCount: 1,
          monthlyCount: 1
        });
      }
  
      setRecentSearches(prev => [symbol, ...prev.filter(s => s !== symbol)].slice(0, 5));
    };
  
   


      


      const sirketgit = async (hisse) => {



        try {
          // Create a document reference with the stock symbol as the ID
          const stockRef = doc(db, "searchedStocks", hisse);
      
          // Get the document
          const stockDoc = await getDoc(stockRef);
      
          if (stockDoc.exists()) {
            // If the document exists, update the counts
            await updateDoc(stockRef, {
              dailyCount: increment(1),
              weeklyCount: increment(1),
              monthlyCount: increment(1),
              lastSearched: serverTimestamp()
            });
          } else {
            // If the document doesn't exist, create it
            await setDoc(stockRef, {
              symbol: hisse,
              dailyCount: 1,
              weeklyCount: 1,
              monthlyCount: 1,
              lastSearched: serverTimestamp()
            });
          }
      
          console.log(`Search recorded for ${hisse}`);
        } catch (error) {
          console.error('Error recording search:', error);
        }


        try {
          let searches = localStorage.getItem('recentSearches');
          searches = searches ? JSON.parse(searches) : [];
      
          // Mevcut hisseyi listeden kaldır (hem eski hem de yeni format için)
          searches = searches.filter(item => 
            (typeof item === 'string' && item !== hisse) || 
            (typeof item === 'object' && item.hisse !== hisse)
          );
      
          // Yeni aramayı listenin başına ekle
          searches.unshift(hisse);
      
          // Sadece son 10 aramayı tut
          searches = searches.slice(0, 3);
      
          localStorage.setItem('recentSearches', JSON.stringify(searches));
      
        } catch (error) {
          console.error('Error saving recent search:', error);
        }
      };

      const loadRecentSearches = async () => {
    try {
      const searches = localStorage.getItem('recentSearches');

      if (searches) {
        const parsedSearches = JSON.parse(searches);
        const updatedSearches = await Promise.all(parsedSearches.map(async (item) => {
          const guncelFiyat = await fetchStockPrice((typeof item === 'string' ? item : item.hisse),urlsi);
          return { 
            hisse: typeof item === 'string' ? item : item.hisse, 
            fiyat: guncelFiyat ? guncelFiyat : [0, 0] 
          };
        }));
        setRecentSearches(updatedSearches.filter(Boolean));
      }
    } catch (error) {
      console.error('Error loading recent searches:', error);
    }
  };

 
  const scrollViewRef = useRef(null);

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: true });
    }
  };

  const loadStockNames = useCallback(async () => {
    try {
      const cachedNames = localStorage.getItem(STOCK_NAMES_CACHE_KEY);
      if (cachedNames) {
        setSirketAdstam(JSON.parse(cachedNames));
      } else {
        const fetchedNames = await fetchStockNames();
        if (fetchedNames?.length > 0) {
          setSirketAdstam(fetchedNames);
          localStorage.setItem(STOCK_NAMES_CACHE_KEY, JSON.stringify(fetchedNames));
        } else {
          console.warn('Fetched stock names were empty, using fallback list');
          setSirketAdstam(yedek);
          localStorage.setItem(STOCK_NAMES_CACHE_KEY, JSON.stringify(yedek));
        }
      }
    } catch (error) {
      console.error('Error loading stock names:', error);
      setSirketAdstam(yedek);
    }
  }, []);

  useEffect(() => {
    loadRecentSearches();
      loadStockNames();

    const fetchData = async () => {
      try {
   
    
        const q = query(
          collection(db, "sunucu")
        );
    
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((docSnapshot) => {
            

              setUrlsi(docSnapshot.data().url)
            
          });
        });
    
        return () => {
          unsubscribe();
        };
    
      } catch (error) {
       
      }
    };
    
    
      
    fetchData();
 
  }, []);





    

// Arama geçmişini yükle
useEffect(() => {
    const loadedSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
    setRecentSearches(loadedSearches);
  }, []);

  
  

  return (
    <div className={[styles.select, className].join(" ")}>
      <div className={styles.sectionParent}>
        <div className={styles.section}>
          <div className={styles.frameWrapper}>
            <img className={styles.frameIcon} alt="" src="/frame.png" />
          </div>
          <div className={styles.text}>Arama Yap</div>
          <img className={styles.frameIcon1} alt="" src="/ucnokta.png" />
        </div>
        <div className={styles.search}>
          <div className={styles.bg} />

          <input
            type="text"
            onChange={(e) => debouncedSearch(e.target.value)}
            placeholder="Hisse Ara"
            className={styles.text1}
            maxLength={6}






          />


          <img
            className={styles.vuesaxtwotonesearchNormalIcon}
            alt=""
            src="/ucnokta.png"
          />
        </div>
        <div className={styles.currency}>
          <div className={styles.frameParent}>

          {aramalistt.length > 0 && (

          <div className={styles.textParent} >
              <div className={styles.text2}>Arama Sonuçlarım ({aramalistt.length})</div>
              <div className={styles.frameParent1}>

              {aramalistt.map((symbol, index) => (

<div className={styles.myfinansLogo31Parent1} onClick={(e) => { e.stopPropagation(); handleHisseClick(symbol.name); }}>

<img
  className={styles.myfinansLogo31Icon}
  alt=""
  src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${symbol.name || 'https://w7.pngwing.com/pngs/865/12/png-transparent-borsa-istanbul-exchange-istanbul-blue-text-trademark-thumbnail'}.png`}
  />
<div className={styles.frameWrapper4}>
  <div className={styles.frameDiv}>
      <div className={styles.text3}>{symbol.name}</div>
   
  </div>
</div>
</div>
              
              ))}

              </div>
            </div>
          )}

{recentSearches.length > 0 && (

            <div className={styles.textParent}>
              <div className={styles.text2}>Arama Geçmişim</div>
              <div className={styles.frameGroup}>

              {recentSearches.map((symbol, index) => (

                <div className={styles.myfinansLogo31Parent}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src="/myfinanslogo3-1@2x.png"
                  />
                  <div className={styles.frameContainer}>
                    <div className={styles.frameDiv}>
                      <div className={styles.textGroup}>
                        <div className={styles.text3}>AKYHO</div>
                        <div className={styles.groupParent}>
                          <div className={styles.textWrapper}>
                            <div className={styles.text4}>19.75 TL</div>
                          </div>
                          <div className={styles.textContainer}>
                            <div className={styles.text5}>3.36%</div>
                            <img className={styles.groupChild} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              ))}

              </div>
            </div>
)}

            <div className={styles.textParent5}>
              <div className={styles.text12}>Popüler Aramalar</div>
              <div className={styles.frameParent1}>


              {topLists.daily.map((key, index) => (
                      <div className={styles.myfinansLogo31Parent1} onClick={(e) => { e.stopPropagation(); handleHisseClick(key.symbol); }}>

                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${key.symbol || 'https://w7.pngwing.com/pngs/865/12/png-transparent-borsa-istanbul-exchange-istanbul-blue-text-trademark-thumbnail'}.png`}
                    />
                  <div className={styles.frameWrapper4}>
                    <div className={styles.frameDiv}>
                      <div className={styles.textGroup}>
                        <div className={styles.text3}>{key.symbol}</div>
                        {stockPrices[key.symbol] ? 

                        <div className={styles.groupParent}>
                          <div className={styles.textWrapper}>
                            <div className={styles.text4}>{stockPrices[key.symbol] ? (stockPrices[key.symbol])["last"]  : "-"} TL</div>
                          </div>

                            { 1 == 2 ? 
                          <div className={styles.textContainer}>
                            <div className={stockPrices[key.symbol]["dailyChangePercentage"] >= 0 ? styles.text5 : styles.text6}>% {stockPrices[key.symbol] ? (stockPrices[key.symbol])["dailyChangePercentage"]  : "--"}</div>
                            <img className={styles.groupChild} alt="" />
                          </div> :  null }


                        </div>
                        : null }
                      </div>
                      <div className={styles.text16}>{key.count} Kez Arandı</div>
                    </div>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
};

export default Select;
