import styles from "./Paket.module.css";

const Paket = () => {
  // Bileşenin en üstünde storedUser'ı tanımlayın
  const storedUser = JSON.parse(localStorage.getItem('user')) || {};

  return (
    <div className={styles.paket}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-12@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.groupParent}>
          <div className={styles.group}>
            <div className={styles.background} />
            <div className={styles.textParent}>
              <b className={styles.text1}>WEB Panel Kullanım Paketlerimiz</b>
              <div className={styles.text2}>
                myFinans mobili ücretsiz bir şekilde kullanabilirsiniz fakat WEB
                Paneli için aşağıdaki paketlerden birine sahip olmalısınız.
              </div>
              <div className={styles.text21}>
                Paketler alım yapıldıktan sonra 30 dakika içinde aktifleşir.
              </div>
              <div className={styles.frameGroup}>


                <div className={styles.frameDiv} onClick={() => window.open(`https://buy.stripe.com/00geVrbikePsgzC5kB?prefilled_email=${storedUser.email}`, '_blank')}>
                  <div className={styles.frameParent1}>
                    <div className={styles.reklamszPaketParent}>
                      <div className={styles.reklamszPaket}>
                        Reklamsız Paket
                      </div>
                      <div className={styles.tlWrapper}>
                        <div className={styles.tl}>19.90 TL</div>
                      </div>
                    </div>
                    <div className={styles.webPaneleEriimContainer}>
                      <ul className={styles.webPaneleEriimReklamszKu}>
                        <li className={styles.webPaneleEriim}>
                          Web Panele Erişim
                        </li>
                        <li className={styles.webPaneleEriim}>
                          Reklamsız Kullanım
                        </li>
                        <li className={styles.webPaneleEriim}>
                          10 Kademe Derinlik (WEB)
                        </li>
                        <li>6 Kademe Derinlik (Mobil)</li>
                      </ul>
                    </div>
                  </div>
                </div>


                <div className={styles.frameWrapper1} onClick={() => window.open(`https://buy.stripe.com/fZe6oVeuw9v84QU28r?prefilled_email=${storedUser.email}`, '_blank')}>
                  <div className={styles.frameParent1}>
                    <div className={styles.sinyalPaketiParent}>
                      <div className={styles.sinyalPaketi}>Sinyal Paketi</div>
                      <div className={styles.tlContainer}>
                        <div className={styles.tl}>199.90 TL</div>
                      </div>
                    </div>
                    <div className={styles.webPaneleEriimContainer}>
                      <ul className={styles.webPaneleEriimReklamszKu}>
                        <li className={styles.webPaneleEriim}>
                          Web Panele Erişim
                        </li>
                        <li className={styles.webPaneleEriim}>
                          10 Kademe Derinlik
                        </li>
                        <li>Yatırım Danışmanı Sinyalleri</li>
                      </ul>
                    </div>
                  </div>
</div>


              </div>
            </div>
          </div>
          <div className={styles.frameParent3}>
            <div className={styles.groupContainer}>
              <div className={styles.frameContainer}>
                <div className={styles.myfinansLogo31Parent}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src="/myfinanslogo3-12@2x.png"
                  />
                  <div className={styles.textWrapper}>
                    <div className={styles.text}>
                      <span>my</span>
                      <span className={styles.finans}>Finans</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.textFrame}>
              <b className={styles.text4}>Yeni Nesil Analiz Platformu</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paket;
