import styles from "./FondagilimiWindow.module.css";
import React, { useEffect, useState,useRef,useCallback,useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot,doc,setDoc,increment,getDoc , serverTimestamp,updateDoc} from "firebase/firestore"; 
import { format, parseISO } from 'date-fns';

 
const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };
const FondagilimiWindow = () => {




    const [aranan, setaranan] = useState('');
    const [urlsi, setUrlsi] = useState('investcodecompany.online');

    const [hisseKodu, setHisseKodu] = useState(() => {
        // Önce localStorage'dan son aranan hisseyi al
        const sonArananHisse = localStorage.getItem('sonArananHisse');
        // Eğer varsa onu kullan, yoksa 'sonSeciliHisse'yi kontrol et
        // O da yoksa "ASELS" kullan
        return sonArananHisse || localStorage.getItem('sonSeciliHisse') || "ASELS";
      });


      useEffect(() => {
    

        const fetchData = async () => {
          try {
       
        
            const q = query(
              collection(db, "sunucu")
            );
        
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              querySnapshot.forEach((docSnapshot) => {
                
    
                  setUrlsi(docSnapshot.data().url)
                
              });
            });
        
            return () => {
              unsubscribe();
            };
        
          } catch (error) {
           
          }
        };
        
        
          
        fetchData();
     
      }, []);



    const [yedek, setyedek] = useState([
        "A1CAP",
        "ACSEL",
        "ADEL",
        "ADESE",
        "ADGYO",
        "AEFES",
        "AFYON",
        "AGESA",
        "AGHOL",
        "AGROT",
        "AGYO",
        "AHGAZ",
        "AKBNK",
        "AKCNS",
        "AKENR",
        "AKFGY",
        "AKFYE",
        "AKGRT",
        "AKMGY",
        "AKSA",
        "AKSEN",
        "AKSGY",
        "AKSUE",
        "AKYHO",
        "ALARK",
        "ALBRK",
        "ALCAR",
        "ALCTL",
        "ALFAS",
        "ALGYO",
        "ALKA",
        "ALKIM",
        "ALKLC",
        "ALMAD",
        "ALTINS1",
        "ALTNY",
        "ALVES",
        "ANELE",
        "ANGEN",
        "ANHYT",
        "ANSGR",
        "ARASE",
        "ARCLK",
        "ARDYZ",
        "ARENA",
        "ARSAN",
        "ARTMS",
        "ARZUM",
        "ASELS",
        "ASGYO",
        "ASTOR",
        "ASUZU",
        "ATAGY",
        "ATAKP",
        "ATATP",
        "ATEKS",
        "ATLAS",
        "ATSYH",
        "AVGYO",
        "AVHOL",
        "AVOD",
        "AVPGY",
        "AVTUR",
        "AYCES",
        "AYDEM",
        "AYEN",
        "AYES",
        "AYGAZ",
        "AZTEK",
        "BAGFS",
        "BAKAB",
        "BALAT",
        "BANVT",
        "BARMA",
        "BASCM",
        "BASGZ",
        "BAYRK",
        "BEGYO",
        "BERA",
        "BEYAZ",
        "BFREN",
        "BIENY",
        "BIGCH",
        "BIMAS",
        "BINHO",
        "BIOEN",
        "BIZIM",
        "BJKAS",
        "BLCYT",
        "BMSCH",
        "BMSTL",
        "BNTAS",
        "BOBET",
        "BORLS",
        "BORSK",
        "BOSSA",
        "BRISA",
        "BRKO",
        "BRKSN",
        "BRKVY",
        "BRLSM",
        "BRMEN",
        "BRSAN",
        "BRYAT",
        "BSOKE",
        "BTCIM",
        "BUCIM",
        "BURCE",
        "BURVA",
        "BVSAN",
        "BYDNR",
        "CANTE",
        "CASA",
        "CATES",
        "CCOLA",
        "CELHA",
        "CEMAS",
        "CEMTS",
        "CEOEM",
        "CIMSA",
        "CLEBI",
        "CMBTN",
        "CMENT",
        "CONSE",
        "CONSER",
        "COSMO",
        "CRDFA",
        "CRFSA",
        "CUSAN",
        "CVKMD",
        "CWENE",
        "DAGHL",
        "DAGI",
        "DAPGM",
        "DARDL",
        "DCTTR",
        "DENGE",
        "DERHL",
        "DERIM",
        "DESA",
        "DESPC",
        "DEVA",
        "DGATE",
        "DGGYO",
        "DGNMO",
        "DIRIT",
        "DITAS",
        "DMRGD",
        "DMSAS",
        "DNISI",
        "DOAS",
        "DOBUR",
        "DOCO",
        "DOFER",
        "DOGUB",
        "DOHOL",
        "DOKTA",
        "DURDO",
        "DYOBY",
        "DZGYO",
        "EBEBK",
        "ECILC",
        "ECZYT",
        "EDATA",
        "EDIP",
        "EFORC",
        "EGEEN",
        "EGEPO",
        "EGGUB",
        "EGPRO",
        "EGSER",
        "EKGYO",
        "EKIZ",
        "EKOS",
        "EKSUN",
        "ELITE",
        "EMKEL",
        "EMNIS",
        "ENERY",
        "ENJSA",
        "ENKAI",
        "ENSRI",
        "ENTRA",
        "EPLAS",
        "ERBOS",
        "ERCB",
        "EREGL",
        "ERSU",
        "ESCAR",
        "ESCOM",
        "ESEN",
        "ETILR",
        "ETYAT",
        "EUHOL",
        "EUKYO",
        "EUPWR",
        "EUREN",
        "EUYO",
        "EYGYO",
        "FADE",
        "FENER",
        "FLAP",
        "FMIZP",
        "FONET",
        "FORMT",
        "FORTE",
        "FRIGO",
        "FROTO",
        "FZLGY",
        "GARAN",
        "GARFA",
        "GEDIK",
        "GEDZA",
        "GENIL",
        "GENTS",
        "GEREL",
        "GESAN",
        "GIPTA",
        "GLBMD",
        "GLCVY",
        "GLRYH",
        "GLYHO",
        "GMTAS",
        "GOKNR",
        "GOLTS",
        "GOODY",
        "GOZDE",
        "GRNYO",
        "GRSEL",
        "GRTRK",
        "GSDDE",
        "GSDHO",
        "GSRAY",
        "GUBRF",
        "GWIND",
        "GZNMI",
        "HALKB",
        "HATEK",
        "HATSN",
        "HDFGS",
        "HEDEF",
        "HEKTS",
        "HKTM",
        "HLGYO",
        "HOROZ",
        "HRKET",
        "HTTBT",
        "HUBVC",
        "HUNER",
        "HURGZ",
        "ICBCT",
        "ICUGS",
        "IDGYO",
        "IEYHO",
        "IHAAS",
        "IHEVA",
        "IHGZT",
        "IHLAS",
        "IHLGM",
        "IHYAY",
        "IMASM",
        "INDES",
        "INFO",
        "INGRM",
        "INTEM",
        "INVEO",
        "INVES",
        "IPEKE",
        "ISATR",
        "ISBIR",
        "ISBTR",
        "ISCTR",
        "ISDMR",
        "ISFIN",
        "ISGSY",
        "ISGYO",
        "ISKPL",
        "ISKUR",
        "ISMEN",
        "ISSEN",
        "ISYAT",
        "IZENR",
        "IZFAS",
        "IZINV",
        "IZMDC",
        "JANTS",
        "KAPLM",
        "KAREL",
        "KARSN",
        "KARTN",
        "KARYE",
        "KATMR",
        "KAYSE",
        "KBORU",
        "KCAER",
        "KCHOL",
        "KENT",
        "KERVN",
        "KERVT",
        "KFEIN",
        "KGYO",
        "KIMMR",
        "KLGYO",
        "KLKIM",
        "KLMSN",
        "KLNMA",
        "KLRHO",
        "KLSER",
        "KLSYN",
        "KMPUR",
        "KNFRT",
        "KOCMT",
        "KONKA",
        "KONTR",
        "KONYA",
        "KOPOL",
        "KORDS",
        "KOTON",
        "KOZAA",
        "KOZAL",
        "KRDMA",
        "KRDMB",
        "KRDMD",
        "KRGYO",
        "KRONT",
        "KRPLS",
        "KRSTL",
        "KRTEK",
        "KRVGD",
        "KSTUR",
        "KTLEV",
        "KTSKR",
        "KUTPO",
        "KUVVA",
        "KUYAS",
        "KZBGY",
        "KZGYO",
        "LIDER",
        "LIDFA",
        "LILAK",
        "LINK",
        "LKMNH",
        "LMKDC",
        "LOGO",
        "LRSHO",
        "LUKSK",
        "MAALT",
        "MACKO",
        "MAGEN",
        "MAKIM",
        "MAKTK",
        "MANAS",
        "MARBL",
        "MARKA",
        "MARTI",
        "MAVI",
        "MEDTR",
        "MEGAP",
        "MEGMT",
        "MEKAG",
        "MEPET",
        "MERCN",
        "MERIT",
        "MERKO",
        "METRO",
        "METUR",
        "MGROS",
        "MHRGY",
        "MIATK",
        "MIPAZ",
        "MMCAS",
        "MNDRS",
        "MNDTR",
        "MOBTL",
        "MOGAN",
        "MPARK",
        "MRGYO",
        "MRSHL",
        "MSGYO",
        "MTRKS",
        "MTRYO",
        "MZHLD",
        "NATEN",
        "NETAS",
        "NIBAS",
        "NTGAZ",
        "NTHOL",
        "NUGYO",
        "NUHCM",
        "OBAMS",
        "OBASE",
        "ODAS",
        "ODINE",
        "OFSYM",
        "ONCSM",
        "ONRYT",
        "ORCAY",
        "ORGE",
        "ORMA",
        "OSMEN",
        "OSTIM",
        "OTKAR",
        "OTTO",
        "OYAKC",
        "OYAYO",
        "OYLUM",
        "OYYAT",
        "OZGYO",
        "OZKGY",
        "OZRDN",
        "OZSUB",
        "OZYSR",
        "PAGYO",
        "PAMEL",
        "PAPIL",
        "PARSN",
        "PASEU",
        "PATEK",
        "PCILT",
        "PEHOL",
        "PEKGY",
        "PENGD",
        "PENTA",
        "PETKM",
        "PETUN",
        "PGSUS",
        "PINSU",
        "PKART",
        "PKENT",
        "PLTUR",
        "PNLSN",
        "PNSUT",
        "POLHO",
        "POLTK",
        "PRDGS",
        "PRKAB",
        "PRKME",
        "PRZMA",
        "PSDTC",
        "PSGYO",
        "QNBFB",
        "QNBFL",
        "QUAGR",
        "RALYH",
        "RAYSG",
        "REEDR",
        "RGYAS",
        "RNPOL",
        "RODRG",
        "ROYAL",
        "RTALB",
        "RUBNS",
        "RYGYO",
        "RYSAS",
        "SAFKR",
        "SAHOL",
        "SAMAT",
        "SANEL",
        "SANFM",
        "SANKO",
        "SARKY",
        "SASA",
        "SAYAS",
        "SDTTR",
        "SEGMN",
        "SEGYO",
        "SEKFK",
        "SEKUR",
        "SELEC",
        "SELGD",
        "SELVA",
        "SEYKM",
        "SILVR",
        "SISE",
        "SKBNK",
        "SKTAS",
        "SKYLP",
        "SKYMD",
        "SMART",
        "SMRTG",
        "SNGYO",
        "SNICA",
        "SNKRN",
        "SNPAM",
        "SODSN",
        "SOKE",
        "SOKM",
        "SONME",
        "SRVGY",
        "SUMAS",
        "SUNTK",
        "SURGY",
        "SUWEN",
        "TABGD",
        "TARKM",
        "TATEN",
        "TATGD",
        "TAVHL",
        "TBORG",
        "TCELL",
        "TDGYO",
        "TEKTU",
        "TERA",
        "TETMT",
        "TEZOL",
        "TGSAS",
        "THYAO",
        "TKFEN",
        "TKNSA",
        "TLMAN",
        "TMPOL",
        "TMSN",
        "TNZTP",
        "TOASO",
        "TRCAS",
        "TRGYO",
        "TRILC",
        "TSGYO",
        "TSKB",
        "TSPOR",
        "TTKOM",
        "TTRAK",
        "TUCLK",
        "TUKAS",
        "TUPRS",
        "TUREX",
        "TURGG",
        "TURSG",
        "UFUK",
        "ULAS",
        "ULKER",
        "ULUFA",
        "ULUSE",
        "ULUUN",
        "UMPAS",
        "UNLU",
        "USAK",
        "UZERB",
        "VAKBN",
        "VAKFN",
        "VAKKO",
        "VANGD",
        "VBTYZ",
        "VERTU",
        "VERUS",
        "VESBE",
        "VESTL",
        "VKFYO",
        "VKGYO",
        "VKING",
        "VRGYO",
        "YAPRK",
        "YATAS",
        "YAYLA",
        "YBTAS",
        "YEOTK",
        "YESIL",
        "YGGYO",
        "YGYO",
        "YIGIT",
        "YKBNK",
        "YKSLN",
        "YONGA",
        "YUNSA",
        "YYAPI",
        "YYLGD",
        "ZEDUR",
        "ZOREN",
        "ZRGYO"
    ]
      );
    

     
    
      const fetchStockNames = useCallback(async () => {
        try {
          const response = await axios.get('https://finans.mynet.com/borsa/hisseler/', {
            headers: { 'User-Agent': 'Mozilla/5.0' }
          });
          const root = parse(response.data);
          const names = root.querySelectorAll('table tbody tr')
            .map(row => {
              const nameElement = row.querySelector('td:first-child strong a');
              return nameElement ? nameElement.text.trim().split(' ')[0] : null;
            })
            .filter(Boolean);
    
          setSirketAdstam(names);
          setisimleri(names);
        } catch (error) {
          setSirketAdstam(yedek);
          setisimleri(yedek);
        }
      }, []);
      const scrollViewRef = useRef(null);

      const scrollToTop = () => {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollTo({ y: 0, animated: true });
        }
      };
    
      
      useEffect(() => {
        

        fetchStockNames();
      }, []);




    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
          const [yabanciveri, setyabanciveri] = useState([]);
    
        const get_yabanci = async (his) => {
            console.log(his)
            setLoaded(false)

            setyabanciveri([])
            
            localStorage.setItem('sonArananHisse', his);
            localStorage.setItem('sonSeciliHisse', his);

            setsecili(his)
            setaramalistt([])
            setaranan(his)
        
          
            const liste = [];
        
            
        
        
            const querySnapshot = await getDocs(collection(db, "fonpozisyon_"+his));
        
            if (!querySnapshot.empty) {
              const enSonDokuman = querySnapshot.docs[querySnapshot.docs.length - 1];
              const data = enSonDokuman.data().fon;
              setyabanciveri(data);
              setLoaded(true)
    
              
            } else {
              setLoaded(true)
            }
        
        
          
        
        };
        
        useEffect(() => {
        
          get_yabanci(secili)
          
        }, []);


        
        const [sirketadstam, setSirketAdstam] = useState([
        ]
        );
        const his_ara = async (hisse) => {
    
            setaranan(hisse.toUpperCase())
        
            if (aranan.length >= 2) {
            const arananHisseler = sirketadstam.filter((item) =>
            item.toUpperCase().startsWith(aranan)
        
        
          );
          setaramalistt(arananHisseler)
          
        
        }
          
          };

         


        const [aramalistt, setaramalistt] = useState(["ASELS"]);

        const [isimleri, setisimleri] = useState([]);

        const [secili, setsecili] = useState(hisseKodu);

        const [loaded, setLoaded] = useState(false);


        const [fveri, setfveri] = useState([]);
        const [hisveri, sethisveri] = useState([]);

        const wsRef = useRef(null);
        const fetchStockPrice = useCallback(() => {
            const fetchData = () => {
              if (urlsi) {
                const url = 'wss://' + urlsi + ':443';
                const tut = []
                wsRef.current = new WebSocket(url);
              
                wsRef.current.onopen = () => {
                  wsRef.current.send(`anlik,${secili}`);
                };
              
                wsRef.current.onmessage = (event) => {
                  try {
                    const data = JSON.parse(event.data);
                    tut.push(data[0].last, data[0].ask, data[0].dailyAmount, data[0].dailyVolume, data[0].dailyChangePercentage, data[0].dailyLowest, data[0].dailyHighest);
              
                    setfveri(tut)
              
                  } catch (e) {
                  } finally {
                  }
                };
              
                wsRef.current.onerror = (error) => {
                };
              
                wsRef.current.onclose = (event) => {
                };
              }
            }
          
            fetchData(); // İlk çağrı
          
            const intervalId = setInterval(fetchData, 6000); // 6 saniyede bir tekrar et
          
            // Temizleme fonksiyonu
            return () => {
              clearInterval(intervalId);
              if (wsRef.current) {
                wsRef.current.close();
              }
            };
          }, [urlsi, secili]);
          
          // useEffect içinde kullanım
          useEffect(() => {
            const cleanup = fetchStockPrice();
            return cleanup;
          }, [fetchStockPrice]);



  return (
    <div className={styles.fondagilimiwindow}>
      <div className={styles.searchParent}>
        <div className={styles.search}>
          <div className={styles.bg} />
          <input
  type="text"
  value={aranan}
  onChange={(e) => his_ara(e.target.value, isimleri)}
  placeholder={"Hisse Ara"}
  className={`${styles.text}`}
  autoComplete="off"
  maxLength={6}
/>                <img
            className={styles.vuesaxtwotonesearchNormalIcon}
            alt=""
            src="/vuesaxtwotonesearchnormal.svg"
          />
        </div>
        {aramalistt.length > 0 && (

<div className={styles.textParent}>
  <div className={styles.text2}>Arama Sonuçları ({aramalistt.length})</div>
  <div className={styles.frameParent}>
  {aramalistt.map((key, index) => (
      <div key={index} className={styles.myfinansLogo31Parent} onClick={(e) => { e.stopPropagation(); get_yabanci(key); }}>

    <div className={styles.myfinansLogo31Parent}>
      <img
        className={styles.myfinansLogo31Icon}
        alt=""
        src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${key || '/myfinanslogo3-1@2x'}.png`}
        />
      <div className={styles.groupDiv}>
        <div className={styles.frameDiv}>
          <div className={styles.textWrapper}>
            <div className={styles.alclar}>{key}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  ))}
  </div>
</div>
)}
         {fveri.length >= 1 && (

<div className={styles.frameGroup}>
  <div className={styles.textParagraphParent}>
    <div className={styles.textParagraph}>{secili}</div>
    <b className={styles.b}>{fveri[0].toFixed(2)} ₺</b>
    <div className={styles.div}>%{fveri[4].toFixed(2)}</div>
  </div>
  <div className={styles.al2800Container}>
    <p className={styles.gnI}>Açılış : {hisveri.acilis || "-"}</p>
    <p className={styles.gnI}>Gün İçi : ₺{fveri[5]} - ₺{fveri[6]}</p>
    <p className={styles.gnI}>Teorik Eşleşme : ₺{fveri[1]} </p>
    <p className={styles.gnI}>İşlem Adeti : {Intl.NumberFormat('en-US').format(fveri[2])}</p>
    <p className={styles.gnI}>İşlem Hacmi : ₺{Intl.NumberFormat('en-US').format(fveri[3])}</p>
  </div>
</div>
)}




        <div className={styles.frameParent1}>
        {yabanciveri && yabanciveri.map((item, index) => (
  <div key={index} className={styles.frameWrapper7}>
    <div className={styles.frameParent2}>
      <div className={styles.b082d21cc5fab17fc8f2d19fb99616Parent}>
        <img
          className={styles.b082d21cc5fab17fc8f2d19fb99616Icon}
          alt=""
          src={item.image_src || '/myfinanslogo3-1@2x.png'}
        />
        <div className={styles.deiimParent}>
          <div className={styles.deiim}>Değişim</div>
          <div className={parseFloat(item.value_3.replace(/[%\s]/g, '').replace(',', '.')) >= 0 ? styles.div1 : styles.div1red}>
            <p className={styles.gnI}>
              <span>{item.value_3}</span>
            </p>
            <p className={parseFloat(item.percentage_3.replace(/[%\s]/g, '').replace(',', '.')) >= 0 ? styles.p1 : styles.p1red}>
              % {item.percentage_3.replace(/[%\s]/g, '')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.frameParent3}>
        <div className={styles.tteParent}>
          <div className={styles.tte}>{item.fon}</div>
          <div className={styles.tl115Container}>
            <span className={styles.tl}>{item.fiyat} TL</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span2}>|</span>
            <span className={parseFloat(item.yuzde.replace(/[%\s]/g, '').replace(',', '.')) >= 0 ? styles.span3 : styles.span3red}>
              <span className={styles.span}>{` `}</span>
              <span>% {item.yuzde}</span>
            </span>
          </div>
        </div>
        <div className={styles.fonBykl}>
          Fon Büyüklüğü : {item.total} TL
        </div>
        <div className={styles.frameParent4}>
          <div className={styles.gncelLotParent}>
            <div className={styles.gncelLot}>Güncel Lot</div>
            <div className={styles.div2}>
              <span className={styles.span5}>{item.value_1}</span>
              <span>{` `}</span>
              <span className={styles.span2}>|</span>
              <span>{` `}</span>
              <span className={styles.span7}>
                <span>% {item.percentage_1.replace(/[%\s]/g, '')}</span>
              </span>
            </div>
          </div>
          <div className={styles.gncelLotParent}>
            <div className={styles.gncelLot}>Önceki Lot</div>
            <div className={styles.div2}>
              <span className={styles.span5}>{item.value_2}</span>
              <span>{` `}</span>
              <span className={styles.span2}>|</span>
              <span>{` `}</span>
              <span className={styles.span7}>
                <span>% {item.percentage_2.replace(/[%\s]/g, '')}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))}
        </div>





      </div>
    </div>
  );
};

export default FondagilimiWindow;
