import styles from "./KayitOl.module.css";

import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState,useRef } from 'react';
import { getAuth, signInWithEmailAndPassword ,browserSessionPersistence, localPersistence } from "firebase/auth";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { updateProfile } from 'firebase/auth';

import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot} from "firebase/firestore"; 
const firebaseConfig = {
  apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
  authDomain: "hisseanaliz-803ed.firebaseapp.com",
  projectId: "hisseanaliz-803ed",
  storageBucket: "hisseanaliz-803ed.appspot.com",
  messagingSenderId: "485376323277",
  appId: "1:485376323277:web:f0f4c904864a26e80a4384",
  measurementId: "G-8YCWPLWTF7"
};


const KayitOl = () => {


  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const auth = getAuth(app);
  
  const kayittamamla = async (idsi) => {
    
    const now = new Date();
    const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
    try {
        // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, "users"), {
        user: isim,
        telefon: telefon,
        email:email,
        sifres:password,
        img:"",
        üyelik:"3",
        userid:idsi,
        üyelik_tarihi:now
      });
      refkayit(idsi)
  
  
  
    } catch (error) {
      console.log("hata:",error)
    }
  }
  const navigate = useNavigate();

  const registrationHandler = async (event) => {
    event.preventDefault();
    setUyari('');

    try {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(response.user, {
        displayName: isim
      });
      
      await kayittamamla(response.user.uid);
      
      localStorage.setItem('user', JSON.stringify(response.user));
      navigate('/anaSayfa');
    } catch (error) {
      let hataMessaji = "Üyelik bilgilerinizi kontrol edin!";
      
      if (error.code === 'auth/email-already-in-use') {
        hataMessaji = "Bu e-posta adresi zaten kullanımda.";
      } else if (error.code === 'auth/invalid-email') {
        hataMessaji = "Geçersiz e-posta adresi.";
      } else if (error.code === 'auth/weak-password') {
        hataMessaji = "Şifre çok zayıf. Lütfen daha güçlü bir şifre seçin.";
      } else if (error.code === 'auth/operation-not-allowed') {
        hataMessaji = "E-posta/şifre girişi etkinleştirilmemiş.";
      } else if (error.code === 'auth/missing-password') {
        hataMessaji = "Şifre giriniz (+6 Karakter)";
      }
      
      setUyari(hataMessaji);
      console.error("Hata:", error);
    }
  };
  const [uyari, setUyari] = useState("");

   
  const kayitOlHandler = () => {
    navigate('/giris');
  };

    
    const refkayit = async (idsi) => {
      
      const now = new Date();
      const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
      try {
        const randomNumber = Math.floor(Math.random() * 1000000) + 1;
    
          // Add a new document with a generated id.
        const docRef = await addDoc(collection(db, "referanslarim"), {
          durum: "Yeni Kayıt",
          islemid: randomNumber,
          kazanc:0,
          kullanan:isim,
          kullananid:idsi,
          kod:ref,
          tarih:now
        });
        navigate('/giris');
    
    
    
      } catch (error) {
      }
    }
    
    const [email, setEmail] = useState('');
    const [telefon, settelefon] = useState('');
  
    const [error,setError] = useState(false);
    const [password, setPassword] = useState('');
    const [isim, setisim] = useState('');
    const [ref, setref] = useState('KRC');
    const [para, setpara] = useState("3");






  return (
    <div className={styles.kayitOl}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameWrapper}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-1@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
          <div className={styles.text1}>Ana Sayfa</div>
          <div className={styles.text1}>Özelliklerimiz</div>
          <div className={styles.text1}>Paketlerimiz</div>
          <div className={styles.text1}>Uygulamamız</div>
          <div className={styles.text1}>Giriş Yap</div>
        </div>
        <div className={styles.groupParent}>
          <div className={styles.group}>
            <div className={styles.background} />
            <div className={styles.textParent}>
              <b className={styles.text6}>Hesap Oluştur</b>
              <div className={styles.text7}>
                Şimdi hemen hesabınızı oluşturup kullanmaya ücretsiz başlayın !
              </div>
              <div className={styles.selectWrapper}>
                <div className={styles.select}>
                  <div className={styles.selectChild} />
                  <div className={styles.textContainer}>
                    <div className={styles.text1}>Kayıt Ol</div>
                  </div>
                  <div className={styles.text9} onClick={kayitOlHandler}>Giriş</div>
                  </div>
              </div>
              <div className={styles.referralCodeOptional}>
                <div className={styles.component4Wrapper}>
                  <div className={styles.component4Wrapper}>
                    <div className={styles.frameContainer}>
                      <div className={styles.rectangleParent}>
                        <div className={styles.frameChild} />
                        <div className={styles.text10}>Full name</div>
                        <img
                          className={styles.frameItem}
                          alt=""
                          src="/line-187.svg"
                        />
                        <img
                          className={styles.frameInner}
                          alt=""
                          src="/group-20468.svg"
                        />
                      </div>
                      <div className={styles.text11}>Erfan amade</div>
                      <img
                        className={styles.groupIcon}
                        alt=""
                        src="/group-20470.svg"
                      />
                      <img
                        className={styles.lineIcon}
                        alt=""
                        src="/line-338.svg"
                      />
                    </div>
                    <input
            type="name"
            value={isim}
            onChange={(e) => setisim(e.target.value)}
            placeholder="İsim"
            className={styles.text12}
          />
                    <img
                      className={styles.component4Child}
                      alt=""
                      src="/line-188.svg"
                    />
                  </div>
                </div>
                <img
                  className={styles.referalCodeIcon}
                  alt=""
                  src="/referalcode.svg"
                />
              </div>
              <div className={styles.referralCodeOptional}>
                <div className={styles.component4Wrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Adresi"
            className={styles.text12}
          />                  <img
                    className={styles.component4Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <img className={styles.mailIcon} alt="" src="/mailicon.svg" />
              </div>
              <div className={styles.referralCodeOptional}>
                <div className={styles.component4Wrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
            type="phone"
            value={telefon}
            onChange={(e) => settelefon(e.target.value)}
            placeholder="Telefon Numaranız"
            className={styles.text12}
          />                  <img
                    className={styles.component4Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <img className={styles.phoneIcon} alt="" src="/phone@2x.png" />
              </div>
              <div className={styles.referralCodeOptional}>
                <div className={styles.component4Wrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <div className={styles.text10}>Full name</div>
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <div className={styles.text11}>Erfan amade</div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Şifreniz"
            className={styles.text12}
          />                  <img
                    className={styles.component4Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
                <img
                  className={styles.passwordIcon}
                  alt=""
                  src="/passwordicon.svg"
                />
              </div>

              <button className={styles.button} onClick={registrationHandler}>

                <div className={styles.buttonChild} />
                <div className={styles.rectangleParent2}>
                  <div className={styles.groupChild} />
                  <img className={styles.frameIcon} alt="" src="/frame1.svg" />
                </div>

                <div className={styles.text22}>Oluştur</div>
                </button>

                {uyari && <div className={styles.text23}>Uyarı !! {uyari}</div>}
                </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.groupContainer}>
              <div className={styles.frameWrapper}>
                <div className={styles.myfinansLogo31Parent}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src="/myfinanslogo3-12@2x.png"
                  />
                  <div className={styles.textWrapper}>
                    <div className={styles.text}>
                      <span>my</span>
                      <span className={styles.finans}>Finans</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.textWrapper1}>
              <b className={styles.text25}>Yeni Nesil Analiz Platformu</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KayitOl;
