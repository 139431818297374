import React, { useEffect, useState,useRef,useCallback,useMemo } from 'react';
import "./AnaSayfa.css";
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot,setDoc,doc,increment,serverTimestamp} from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom'; // Bu satırı ekleyin
import Select from './Select'; // select.js dosyasını import ediyoruz
import LeftSideMenu from './LeftSideMenu';
import TopMenu from './TopMenu'; // Yeni bileşeni import edin

import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
  authDomain: "hisseanaliz-803ed.firebaseapp.com",
  projectId: "hisseanaliz-803ed",
  storageBucket: "hisseanaliz-803ed.appspot.com",
  messagingSenderId: "485376323277",
  appId: "1:485376323277:web:f0f4c904864a26e80a4384",
  measurementId: "G-8YCWPLWTF7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const AnaSayfa = () => {
  const [activeTab, setActiveTab] = useState('Yükselenler');
  const [activemenu, setactivemenu] = useState(false);
  const navigate = useNavigate(); // Bu satırı ekleyin

  const tabs = ['Takip Listem', 'Yükselenler', 'Düşenler', 'Hacimliler'];

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/giris');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);
  


  const wsRef = useRef(null);
  const [urlsi, setUrlsi] = useState('investcodecompany.online');
  const fetchStockPrice = (hisse) => {
    return new Promise((resolve, reject) => {
      const url = 'wss://'+urlsi+':443';
      
      const ws = new WebSocket(url);
      
      ws.onopen = () => {
        ws.send(`anlik,${hisse}`);
      };
      
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          resolve(data[0]);
        } catch (e) {
          console.error('JSON parse error:', e);
          reject(e);
        } finally {
          ws.close();
        }
      };
      
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };
      
      ws.onclose = (event) => {
      };
  
      setTimeout(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          reject(new Error('WebSocket connection timeout'));
        }
      }, 10000);
    });
  };



  
  const [bist100, setbist100] = useState([]);
  const [dolar, setdolar] = useState([]);
  const [altin, setaltin] = useState([]);
  const [btc, setbtc] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataPromise = Promise.all([
          //tlistem(),
          
        
          getMidasYukselen(),
          getMidasDusen(),
          get_hacimli(),

        ]);
  
  
        await Promise.all([dataPromise]);
      } catch (error) {
        console.error("Data Fetch Error:", error);
  
      } finally {
      }
    };
  
    fetchData();
  }, [])


  


  const get_hacimli = async (ner) => {
    try {
      const url = 'https://tr.tradingview.com/markets/stocks-turkey/market-movers-active/';
      const response = await axios.get(url);
      const html = response.data;
      const $ = cheerio.load(html);
      
      const tbody = $('tbody');
      const extractedData = [];
  
      tbody.find('tr').each((index, element) => {
        const row = [];
        $(element).find('td').each((index, cell) => {
          if (index === 0) {
            const ticker = $(cell).find('a').text().trim();
            const description = $(cell).find('sup').text().trim();
            let cellContent = ticker;
            if (description) {
              cellContent += `*${description}`;
            }
            row.push(cellContent);
          } else {
            const cellTexts = $(cell).contents().map((i, el) => $(el).text().trim()).get();
            const cellContent = cellTexts.join('*');
            row.push(cellContent);
          }
        });
        extractedData.push(row);
      });
      sethacimli(extractedData)
      console.log(extractedData[0])
  
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };



  const getMidasYukselen = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: 'https://www.getmidas.com/wp-json/midas-api/v1/midas_table_data',
        params: {
          sortId: 'en-cok-artan-hisseler',
          return: 'table'
        },
        headers: {
          'Content-Type': 'application/json',
        },
        http2: true, // HTTP/2'yi etkinleştirmek için
      });
  
      
      setyukselenler(JSON.parse(response.data).slice(0,31))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getMidasDusen = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: 'https://www.getmidas.com/wp-json/midas-api/v1/midas_table_data',
        params: {
          sortId: 'en-cok-azalan-hisseler',
          return: 'table'
        },
        headers: {
          'Content-Type': 'application/json',
        },
        http2: true, // HTTP/2'yi etkinleştirmek için
      });
  
      
      setdusenler(JSON.parse(response.data).slice(0,31))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [yukselenler, setyukselenler] = useState([]);
  const [dusenler, setdusenler] = useState([]);
  const [hacimli, sethacimli] = useState([]);


  const [hangi, sethangi] = useState(0);


  const handleHisseClick = (hisseKodu) => {
    navigate(`/derinlik/${hisseKodu}`);
  };

  const handleSearchClick = () => {
    setIsSearchPopupOpen(true);
  };

  const closeSearchPopup = () => {
    setIsSearchPopupOpen(false);
  };

  return (
    <div className="ana-sayfa">
      <div className="left-side-parent">

      <LeftSideMenu isActive={activemenu} onToggle={() => setactivemenu(!activemenu)} />


        <div className="frame-container">




          <TopMenu 
            onMenuToggle={() => setactivemenu(!activemenu)}
            onSearchClick={handleSearchClick}
          />





          <div className="section-parent">
            <div className="section">
              <div className="group-parent">
                <div className="text-parent">
                  <div className="text1">Piyasa Verileri</div>
                  <div className="text14">Canlı</div>
                </div>
                <div className="switch">
                  <div className="group-container">
                    {tabs.map((tab) => (
                      <div 
                        key={tab}
                        className={`frame-wrapper ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                      >
                        <div className={activeTab === tab ? "text-container" : "text-frame"}>
                          <div className="text11">{tab}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <img className="view-icon" alt="" src="/view.svg" />
              </div>
            </div>
            <div className="frame-parent1">
              <div className="text-group">
                <div className="text11">#</div>
                <div className="text-parent1">
                  <div className="text11">Hisse</div>
                  <img className="arrows-icon" alt="" src="/arrows.svg" />
                </div>
              </div>
              <div className="text-parent2">
                <div className="text11">Fiyat</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="text-parent2">
                <div className="text11">%</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="text-parent2">
                <div className="text11">Haftalık %</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="text-parent5">
                <div className="text11">Hacim (TL)</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="text-parent5">
                <div className="text11">Hacim (Lot)</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="text-parent5">
                <div className="text11">Gün İçi Değişim</div>
                <img className="arrows-icon" alt="" src="/arrows.svg" />
              </div>
              <div className="add">
                <img className="frame-icon" alt="" src="/frame.svg" />
              </div>
            </div>
            <div className="frame-child" />
            <div className="sicaklik" />
            <div className="listeli">

{activeTab === 'Yükselenler' && (
              yukselenler.map((hisse, index) => (
                <div className="listeli-inner" key={index} onClick={() => handleHisseClick(hisse["Code"])}>
                  <div className="frame-parent2">
                    <div className="frame-parent3">
                      <div className="text-parent8">
                        <div className="text11">{index+1}</div>
                        <img
  className="myfinans-logo-3-1-icon1"
  alt=""
  src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${hisse["Code"] || 'https://w7.pngwing.com/pngs/865/12/png-transparent-borsa-istanbul-exchange-istanbul-blue-text-trademark-thumbnail'}.png`}
/>
                        <div className="text-parent9">
                          <div className="text28">{hisse["Code"]}</div>
                          <div className="text29">
                          </div>
                        </div>
                      </div>
                      <div className="text11">{hisse["Close"].toFixed(2)} ₺</div>
                      <div className={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                        <img
                          className="change-child"
                          alt=""
                          src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                        />
                        <div className="change-inner">
                          <div className="text-parent10">
                            <div className={`text3 ${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["DailyChangePercent"].toFixed(2)}%</div>
                            <img
                              className="group-child"
                              alt=""
                              src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                        <img
                          className="change-child"
                          alt=""
                          src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                        />
                            <div className={`text3 ${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["WeeklyChangePercent"].toFixed(2)}%</div>
                            <img
                          className="group-inner"
                          alt=""
                          src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                          />
                      </div>
                      <div className="text33">
                        {new Intl.NumberFormat('tr-TR', {
                          style: 'currency',
                          currency: 'TRY',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(hisse["TotalTurnover"])}
                      </div>
                      <div className="text11">
                      {new Intl.NumberFormat('tr-TR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(hisse["TotalVolume"])}
                      </div>
                      <div className="text35">{hisse["Low"]}₺ - {hisse["High"]}₺</div>
                    </div>
                    <div className="button-parent">
                      <div className="button" onClick={(e) => { e.stopPropagation(); handleHisseClick(hisse["Code"]); }}>
                        <div className="text11">İncele</div>
                      </div>
                      <img className="arrows-icon" alt="" src="/staricon.svg" />
                      <img className="arrows-icon" alt="" src="/staricon1.svg" />
                    </div>
                  </div>
                </div>
              ))
)}  

{activeTab === 'Düşenler' && (

dusenler.map((hisse, index) => (
  <div className="listeli-inner">
                <div className="frame-parent2">
                  <div className="frame-parent3">
                    <div className="text-parent8">
                      <div className="text11">{index+1}</div>
                      <img
  className="myfinans-logo-3-1-icon1"
  alt=""
  src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${hisse["Code"] || '/myfinanslogo3-1@2x'}.png`}
/>
                      <div className="text-parent9">
                        <div className="text28">{hisse["Code"]}</div>
                        <div className="text29">
                        </div>
                      </div>
                    </div>
                    <div className="text11">{hisse["Close"].toFixed(2)} ₺</div>
                    <div className={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                      <img
                        className="change-child"
                        alt=""
                        src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                      />
                      <div className="change-inner">
                        <div className="text-parent10">
                          <div className={`text3 ${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["DailyChangePercent"].toFixed(2)}%</div>
                          <img
                            className="group-child"
                            alt=""
                            src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                      <img
                        className="change-child"
                        alt=""
                        src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                      />
                          <div className={`text3 ${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["WeeklyChangePercent"].toFixed(2)}%</div>
                          <img
                        className="group-inner"
                        alt=""
                        src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                        />
                    </div>
                    <div className="text33">
                      {new Intl.NumberFormat('tr-TR', {
                        style: 'currency',
                        currency: 'TRY',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(hisse["TotalTurnover"])}
                    </div>
                    <div className="text11">
                    {new Intl.NumberFormat('tr-TR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(hisse["TotalVolume"])}
                    </div>
                    <div className="text35">{hisse["Low"]}₺ - {hisse["High"]}₺</div>
                  </div>
                  <div className="button-parent">
                    <div className="button">
                      <div className="text11">İncele</div>
                    </div>
                    <img className="arrows-icon" alt="" src="/staricon.svg" />
                    <img className="arrows-icon" alt="" src="/staricon1.svg" />
                  </div>
                </div>
              </div>
              ))
)}

{activeTab === 'Hacimliler' && (

hacimli.map((hisse, index) => (
  <div className="listeli-inner">
                <div className="frame-parent2">
                  <div className="frame-parent3">
                    <div className="text-parent8">
                      <div className="text11">{index+1}</div>
                      <img
  className="myfinans-logo-3-1-icon1"
  alt=""
  src={`https://fws.forinvestcdn.com/cdn/symbol-logos/BIST/${hisse["Code"] || '/myfinanslogo3-1@2x'}.png`}
/>
                      <div className="text-parent9">
                        <div className="text28">{hisse["Code"]}</div>
                        <div className="text29">
                        </div>
                      </div>
                    </div>
                    <div className="text11">{hisse["Close"].toFixed(2)} ₺</div>
                    <div className={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                      <img
                        className="change-child"
                        alt=""
                        src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                      />
                      <div className="change-inner">
                        <div className="text-parent10">
                          <div className={`text3 ${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["DailyChangePercent"].toFixed(2)}%</div>
                          <img
                            className="group-child"
                            alt=""
                            src={`${parseFloat(hisse["DailyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? 'change' : 'vector-parent'}`}>
                      <img
                        className="change-child"
                        alt=""
                        src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/rectangle-7616.svg' : '/rectangle-76161.svg'}`}
                      />
                          <div className={`text3 ${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '1' : '2'}`}>{hisse["WeeklyChangePercent"].toFixed(2)}%</div>
                          <img
                        className="group-inner"
                        alt=""
                        src={`${parseFloat(hisse["WeeklyChangePercent"]) >= 0 ? '/polygon-2.svg' : '/polygon-21.svg'}`}
                        />
                    </div>
                    <div className="text33">
                      {new Intl.NumberFormat('tr-TR', {
                        style: 'currency',
                        currency: 'TRY',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(hisse["TotalTurnover"])}
                    </div>
                    <div className="text11">
                    {new Intl.NumberFormat('tr-TR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(hisse["TotalVolume"])}
                    </div>
                    <div className="text35">{hisse["Low"]}₺ - {hisse["High"]}₺</div>
                  </div>
                  <div className="button-parent">
                    <div className="button">
                      <div className="text11">İncele</div>
                    </div>
                    <img className="arrows-icon" alt="" src="/staricon.svg" />
                    <img className="arrows-icon" alt="" src="/staricon1.svg" />
                  </div>
                </div>
              </div>
              ))
  
)}


            </div>
          </div>
        </div>
      </div>

      {isSearchPopupOpen && (
        <div className="search-popup-overlay">
          <div className="search-popup">
            <button className="close-button" onClick={closeSearchPopup}>X</button>
            <div className="select-container">
              <Select /> {/* Select bileşenini burada kullanıyoruz */}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default AnaSayfa;