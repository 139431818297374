import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./GirisYap.module.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
  authDomain: "hisseanaliz-803ed.firebaseapp.com",
  projectId: "hisseanaliz-803ed",
  storageBucket: "hisseanaliz-803ed.appspot.com",
  messagingSenderId: "485376323277",
  appId: "1:485376323277:web:f0f4c904864a26e80a4384",
  measurementId: "G-8YCWPLWTF7"
};
const GirisYap = () => {
  const [email, setEmail] = useState("");
  const [sifre, setSifre] = useState("");
  const [uyari, setUyari] = useState("");
  const navigate = useNavigate();

  const kayitOlHandler = () => {
    navigate('/kayit');
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    setUyari("");
    
    try {
      const auth = getAuth();
      const response = await signInWithEmailAndPassword(auth, email, sifre);
      const user = response.user;
      
      // Kullanıcı verilerini localStorage'a kaydet
      localStorage.setItem('user', JSON.stringify({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified
      }));
      
      navigate('/test');
    } catch (error) {
      console.error(error);
      setUyari("Email veya Şifre Hatası!");
    }
  };

  return (
    <div className={styles.girisYap}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameWrapper}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-1@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
          <div className={styles.text1}>Ana Sayfa</div>
          <div className={styles.text1}>Özelliklerimiz</div>
          <div className={styles.text1}>Paketlerimiz</div>
          <div className={styles.text1}>Uygulamamız</div>
          <div className={styles.text1}>Giriş Yap</div>
        </div>
        <div className={styles.groupParent}>
          <div className={styles.group}>
            <div className={styles.background} />
            <div className={styles.textParent}>
              <b className={styles.text6}>Giriş Yap</b>
              <div className={styles.text7}>
                myFinans mobil uygulamasındaki giriş bilgileriniz ile giriş
                yapabilirsiniz.
              </div>
              <div className={styles.selectWrapper}>
                <div className={styles.select}>
                  <div className={styles.selectChild} />
                  <div className={styles.text9} onClick={kayitOlHandler}>Kayit Ol</div>
                  <div className={styles.textContainer}>
                    <div className={styles.text1}>Giriş</div>
                  </div>
                  
                </div>
              </div>
              <div className={styles.emailAddress}>
                <div className={styles.component2}>
                  <div className={styles.frameContainer}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <div className={styles.text10}>Full name</div>
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <div className={styles.text11}>Erfan amade</div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Addresi"
            className={styles.text12}
          />
                  <img
                    className={styles.component2Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <img className={styles.mailIcon} alt="" src="/mailicon.svg" />
              </div>
              <div className={styles.emailAddress}>
                <div className={styles.component2}>
                  <div className={styles.frameContainer}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.frameChild} />
                      <img
                        className={styles.frameItem}
                        alt=""
                        src="/line-187.svg"
                      />
                      <img
                        className={styles.frameInner}
                        alt=""
                        src="/group-20468.svg"
                      />
                    </div>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-20470.svg"
                    />
                    <img
                      className={styles.lineIcon}
                      alt=""
                      src="/line-338.svg"
                    />
                  </div>
                  <input
            type="password"
            value={sifre}
            onChange={(e) => setSifre(e.target.value)}
            placeholder="Şifre"
            className={styles.text12}
          />
                  <img
                    className={styles.component2Child}
                    alt=""
                    src="/line-188.svg"
                  />
                </div>
                <img
                  className={styles.passwordIcon}
                  alt=""
                  src="/passwordicon.svg"
                />
              </div>
              <button className={styles.button} onClick={loginHandler}>
              <div className={styles.buttonChild} />
                <div className={styles.rectangleContainer}>
                  <div className={styles.groupChild} />
                  <img className={styles.frameIcon} alt="" src="/frame1.svg" />
                </div>
                <div className={styles.text16}>Giriş Yap</div>
                </button>
                {uyari && (
        <div className={styles.text17}>
          Uyarı !! {uyari}
        </div>
      )}
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.groupContainer}>
              <div className={styles.frameWrapper}>
                <div className={styles.myfinansLogo31Parent}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src="/myfinanslogo3-12@2x.png"
                  />
                  <div className={styles.textWrapper}>
                    <div className={styles.text}>
                      <span>my</span>
                      <span className={styles.finans}>Finans</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.textWrapper1}>
              <b className={styles.text19}>Yeni Nesil Analiz Platformu</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GirisYap;
